/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import axios from "axios";
import Header from "../Header";
import {
  faBackwardStep,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Full name is required.")
    .min(8, "Too Short!")
    .max(60, "Too Long"),
  phoneNumber: Yup.string()
    .matches(/^\d{9,10}$/, "Phone number must be 9 or 10 digits.")
    .required("Phone number is required."),
  address: Yup.string()
    .required("Address is required")
    .min(3, "Too Short!")
    .max(30, "Too Long"),
});
const VendorPage = () => {
  const [open, setOpen] = useState(false);
  const [vendors, setVendors] = useState([]);

  const fetchAllData = async () => {
    try {
      const [vendor] = await Promise.all([
        axios.get("https://garment.server.highendtradingplc.com/vendor"),
      ]);
      setVendors(vendor.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      address: "",
      phoneNumber: "",
      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.id === "") {
          const { phoneNumber } = values;
          const userExists = vendors.find(
            (vendor) => vendor?.phoneNumber === phoneNumber + ""
          );
          if (userExists) {
            formik.setErrors({
              phoneNumber: "Phone number already exists.",
            });
            return;
          }
          alert("Data Add Successfully!");
          await axios.post(
            "https://garment.server.highendtradingplc.com/vendor/add",
            values
          );
          setOpen(false);
        } else {
          alert("Data Update Successfully!");
          await axios.put(
            "https://garment.server.highendtradingplc.com/vendor/update/" +
              values.id,
            values
          );
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        fetchAllData();
      }
    },
  });
  const handleAdd = () => {
    formik.values.address = "";
    formik.values.fullName = "";
    formik.values.phoneNumber = "";

    formik.values.id = "";
    setOpen(true);
  };
  const handleUpdate = (id) => {
    const vendor = vendors.find((cust) => cust.Id === parseInt(id));
    formik.values.address = vendor.address;
    formik.values.fullName = vendor.fullName;
    formik.values.phoneNumber = vendor.phoneNumber;

    formik.values.id = parseInt(id);
    setOpen(true);
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        "https://garment.server.highendtradingplc.com/vendor/delete/" + id
      );
      fetchAllData();
    } catch (error) {}
  };

  const handleOpen = () => {
    setOpen(false);
  };
  const columns = [
    {
      field: "Id",
      headerAlign: "center",
      align: "center",
      headerName: "ID",
      minWidth: 80,
    },
    {
      field: "fullName",
      headerAlign: "center",
      align: "center",
      headerName: "vendor Name",
      minWidth: 150,
    },

    {
      field: "phoneNumber",
      headerAlign: "center",
      align: "center",
      headerName: "Phone Number",
      minWidth: 150,
    },
    {
      field: "address",
      headerAlign: "center",
      align: "center",
      headerName: "Address",
      minWidth: 120,
    },
    {
      field: "action",
      headerAlign: "center",
      align: "center",
      headerName: "Action",
      minWidth: 240,

      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  return (
    <div className="">
      <div>
        <Header />
      </div>
      <main className="" style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div className="">
              <div className="card card-info card-outline">
                <div className="card-header pl-2">
                  {!open && (
                    <Button
                      variant="outline-info"
                      style={{ width: "150px", height: "35px", float: "left" }}
                      onClick={handleAdd}
                    >
                      <h3
                        style={{
                          float: "left",
                          marginTop: "-6px",
                          marginRight: "5px",
                        }}
                      >
                        +
                      </h3>
                      <h6 style={{ float: "left", marginTop: "1px" }}>
                        {" "}
                        Create New
                      </h6>
                    </Button>
                  )}
                  {open && (
                    <div>
                      <h5 style={{ float: "left" }}>
                        {formik.values.id === "" ? "Create New" : "Update"}{" "}
                        Vendor
                      </h5>
                      <Button
                        onClick={handleOpen}
                        variant="outline-success"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{ float: "right", marginRight: "-1px" }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                              }}
                            >
                              Back
                            </h6>
                          </div>
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
                <div className="card-body">
                  {!open && (
                    <div>
                      <div
                        className="nav-bra pl-3 ml-1 p-2"
                        style={{
                          backgroundColor: "rgb(235, 235, 235)",
                          height: "40px",
                        }}
                      >
                        <span style={{ float: "left" }}>Home / Vendor </span>
                      </div>
                      <hr />
                    </div>
                  )}
                  {open && (
                    <div>
                      <div
                        className="nav-bra pl-3 ml-1 p-2"
                        style={{
                          backgroundColor: "rgb(235, 235, 235)",
                        }}
                      >
                        <span>
                          Home / Vendor / {""}{" "}
                          {formik.values.id === "" ? "Create" : "Update"}{" "}
                        </span>
                      </div>
                      <hr />
                    </div>
                  )}
                  {!open && (
                    <DataGrid
                      rows={vendors}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      columns={columns}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      initialState={{
                        ...vendors.initialState,
                        pagination: {
                          paginationModel: { pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10, 15]}
                      getRowId={(row) => row.Id}
                    />
                  )}
                  {open && (
                    <center>
                      <Form
                        className="m-2 ml-5 pl-5"
                        onSubmit={formik.handleSubmit}
                      >
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              label="Full Name"
                              variant="outlined"
                              size="small"
                              type="text"
                              name="fullName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.fullName}
                              error={
                                formik.touched.fullName &&
                                formik.errors.fullName
                              }
                              helperText={
                                formik.touched.fullName &&
                                formik.errors.fullName
                                  ? `${formik.errors.fullName}`
                                  : ""
                              }
                              style={{ width: "300px" }}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        ></Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              label="Phone Number"
                              variant="outlined"
                              size="small"
                              type="text"
                              name="phoneNumber"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.phoneNumber}
                              error={
                                formik.touched.phoneNumber &&
                                formik.errors.phoneNumber
                              }
                              helperText={
                                formik.touched.phoneNumber &&
                                formik.errors.phoneNumber
                                  ? `${formik.errors.phoneNumber}`
                                  : ""
                              }
                              style={{ width: "300px" }}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              label="Address"
                              variant="outlined"
                              size="small"
                              type="text"
                              name="address"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.address}
                              error={
                                formik.touched.address && formik.errors.address
                              }
                              helperText={
                                formik.touched.address && formik.errors.address
                                  ? `${formik.errors.address}`
                                  : ""
                              }
                              style={{ width: "300px" }}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <Button
                              style={{ width: "300px" }}
                              variant="outline-success"
                              type="submit"
                            >
                              {formik.values.id === "" ? "Create" : "Update"}
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    </center>
                  )}
                </div>
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};

export default VendorPage;
