/** @format */
import React, { useEffect, useState } from "react";
import Header from "../Header";

import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Autocomplete, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faUser,
  faBackwardStep,
  faFloppyDisk,
  faCalendar,
  faPenToSquare,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
const validationSchema = Yup.object().shape({
  date: Yup.date().required("Date is required!"),
});
function RawPurchasePage() {
  const [open, setOpen] = useState(false);
  const [openMaterial, setOpenMaterial] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [colors, setColors] = useState([]);
  const [units, setUnits] = useState(null);
  const [rawMaterialId, setrawMaterialId] = useState(null);
  const [rawMaterialCheck, setRawMaterialCheck] = useState(null);
  const [vendorCheck, setVendorCheck] = useState(null);
  const [vendorValue, setVendorValue] = useState(null);
  const [rawMaterialValue, setRawMaterialValue] = useState([]);
  const [valids, setValid] = useState([0, 0]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [taxs, setTaxs] = useState([]);
  const [types, setTypes] = useState([]);
  const [isLoding, setIsLoding] = useState(false);
  const [update, setUpdate] = useState({
    rawMaterialId: "",
    quantity: "",
    price: "",
  });
  const [price, setPrice] = useState({
    total: 0,
    vat: 0,
    grandTotal: 0,
  });
  const fetchAllData = async () => {
    try {
      const [vendor, rawMaterial, unit, color, type] = await Promise.all([
        axios.get("https://garment.server.highendtradingplc.com/vendor"),
        axios.get("https://garment.server.highendtradingplc.com/rawMaterial"),
        axios.get("https://garment.server.highendtradingplc.com/unit"),
        axios.get("https://garment.server.highendtradingplc.com/color"),
        axios.get("https://garment.server.highendtradingplc.com/type"),
      ]);
      setTypes(type.data.filter((t) => t.Id > 3));
      setVendors(vendor.data);
      setRawMaterials(rawMaterial.data);
      setColors(color.data);
      setUnits(unit.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);
  const handleOpen = () => {
    setOpen(!open);
  };
  const rawMaterialData = {
    options: rawMaterials,
    getOptionLabel: (option) => option.name,
  };
  const vendorData = {
    options: vendors,
    getOptionLabel: (option) => option.fullName,
  };
  const formik = useFormik({
    initialValues: {
      vendorId: "",
      date: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      //  setIsLoding(true);
      alert("Data Add Successfully!");
      try {
        await axios
          .post(
            "https://garment.server.highendtradingplc.com/rawPurchaseRef/add",
            {
              totalPrice: price.grandTotal,
              vendorId: values.vendorId,
              date: values.date,
            }
          )
          .then(async (res) => {
            for (let i = 0; i <= rawMaterialValue.length - 1; ) {
              await axios.post(
                "https://garment.server.highendtradingplc.com/rawPurchase/add",
                {
                  rawMaterialId: parseInt(rawMaterialValue[i].rawMaterialId),
                  rawPurchaseRefId: res.data.data.Id,
                  price: rawMaterialValue[i].price,
                  quantity: rawMaterialValue[i].quantity,
                }
              );
              await axios.put(
                "https://garment.server.highendtradingplc.com/rawMaterial/update/" +
                  parseInt(rawMaterialValue[i].rawMaterialId),
                {
                  quantity:
                    parseInt(rawMaterialValue[i].rawQuantity) +
                    parseInt(rawMaterialValue[i].quantity),
                }
              );
              i = i + 1;
            }
          });
      } catch (error) {
        console.log(error);
      } finally {
        window.location.reload();
      }
    },
  });
  const clearData = () => {
    price.total = 0;
    price.vat = 0;
    price.grandTotal = 0;
    setRawMaterialValue([]);
  };
  const handleValidity = (i) => {
    valids[i] = 1;
    setValid([...valids]);
    console.log(valids);
  };
  return (
    <div>
      {isLoding && <div>Loding...</div>}
      {!isLoding && (
        <div>
          <div>
            <Header />
          </div>

          <main style={{ marginTop: "190px" }}>
            <center>
              <div style={{ maxWidth: "1100px" }}>
                <div
                  className="tab-container "
                  style={{
                    marginTop: "-20px",
                    marginBottom: "-17px",
                  }}
                >
                  <Link className="p-2" to={`/raw_material`}>
                    Raw Material
                  </Link>
                  <Link className="p-2" to={`/products`}>
                    Product
                  </Link>
                  <Link
                    className="p-2"
                    onClick={(e) => window.location.reload()}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px 5px 0 0",
                      border: "solid 0.05em rgb(181, 181, 181)",
                    }}
                  >
                    Raw Purchase
                  </Link>
                  <Link className="p-2" to={`/raw_purchase_list`}>
                    Purchase List
                  </Link>
                </div>
                <hr />
                <div className="card card-info card-outline">
                  <div className="card-header">
                    <h4 style={{ float: "left" }}>New Raw Purchase Entry</h4>
                  </div>
                  <div className="card-body">
                    <div
                      className="nav-bra pl-3 ml-1 p-2 "
                      style={{
                        backgroundColor: "rgb(235, 235, 235)",
                        height: "40px",
                      }}
                    >
                      <span style={{ float: "left" }}>/ Purchase / Create</span>
                    </div>
                    <hr />
                    <div>
                      <div>
                        <Form className="">
                          <Form.Group
                            as={Row}
                            controlId="formHorizontalUsername"
                          >
                            <Col md={6} className="mb-3">
                              <Autocomplete
                                {...vendorData}
                                id="controlled-demo"
                                size="small"
                                variant="outlined"
                                name="vendorId"
                                onBlur={(e) => handleValidity(0)}
                                value={vendorValue}
                                onChange={(event, newValue) => {
                                  if (!newValue?.Id) {
                                    setVendorCheck(null);
                                    return;
                                  }
                                  setVendorValue(newValue);
                                  formik.values.vendorId = newValue?.Id;
                                  setVendorCheck(5);
                                }}
                                style={{ width: "200px", float: "left" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={
                                      valids[0] === 1 && vendorCheck === null
                                    }
                                    label={
                                      <div style={{ float: "left" }}>
                                        <FontAwesomeIcon
                                          icon={faUser}
                                          style={{
                                            float: "left",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Vendor</span>
                                      </div>
                                    }
                                  />
                                )}
                              />

                              <Button
                                variant="outline-info"
                                //onClick={openAddModal}
                                style={{
                                  width: "30px",
                                  height: "40px",
                                  marginLeft: "20px",
                                  float: "left",
                                }}
                                onClick={handleOpen}
                              >
                                <h2
                                  style={{
                                    marginTop: "-5px",
                                    marginLeft: "-7px",
                                  }}
                                >
                                  +
                                </h2>
                              </Button>
                            </Col>
                            <Col md={6} className="mb-3">
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.date}
                                type="date"
                                label={
                                  <div style={{ float: "left", width: "70px" }}>
                                    <FontAwesomeIcon
                                      icon={faCalendar}
                                      size="1x"
                                      style={{
                                        float: "left",
                                        marginTop: "5px",
                                        marginRight: "5px",
                                      }}
                                    />
                                    <h4>Date</h4>
                                  </div>
                                }
                                size="small"
                                name="date"
                                error={
                                  formik.touched.date && formik.errors.date
                                }
                                helperText={
                                  formik.touched.date && formik.errors.date
                                    ? `${formik.errors.date}`
                                    : ""
                                }
                                style={{ width: "250px", float: "left" }}
                              />
                            </Col>
                          </Form.Group>
                        </Form>
                      </div>
                      <Formik
                        initialValues={{
                          rawMaterialId: "",
                          quantity: "",
                          price: "",
                          //   taxId:"",
                          totalprice: "",
                          rawQuantity: "",
                        }}
                        validationSchema={Yup.object().shape({
                          quantity: Yup.string().required(
                            "Quantity is required"
                          ),
                          price: Yup.string().required("Price is required"),
                        })}
                        onSubmit={async (values) => {
                          try {
                            if (rawMaterialCheck === null) {
                              return;
                            }
                            const checkItem = rawMaterialValue?.find(
                              (i) => i?.rawMaterialId === rawMaterialId.Id
                            );
                            if (!!checkItem) {
                              alert("You use the same rawMaterial!");
                              setrawMaterialId(null);
                              return;
                            }
                            update.price = values.price;
                            update.quantity = values.quantity;
                            values.totalprice = update.quantity * update.price;
                            price.vat = 0;
                            price.total =
                              parseFloat(price.total) +
                              parseFloat(values.totalprice);
                            price.grandTotal = price.total + price.vat;
                            const qty = rawMaterialId.quantity;
                            setrawMaterialId(null);
                            setRawMaterialValue([
                              ...rawMaterialValue,
                              {
                                rawMaterialId: values.rawMaterialId,
                                quantity: update.quantity,
                                price: update.price,
                                totalprice: values.totalprice,
                                rawQuantity: qty,
                              },
                            ]);
                          } catch (error) {
                            console.log(error);
                          } finally {
                            values.price = "";
                            values.quantity = "";
                          }
                        }}
                      >
                        {(props) => (
                          <div className="">
                            <hr />
                            <h3>Purchase </h3>
                            <hr />
                            <Form onSubmit={props.handleSubmit}>
                              <Form.Group
                                as={Row}
                                controlId="formHorizontalUsername"
                              >
                                <Col md={6} className="mb-3">
                                  <Row
                                    style={{ width: "320px", float: "left" }}
                                  >
                                    <div className="col">
                                      <Autocomplete
                                        {...rawMaterialData}
                                        id="controlled-demo"
                                        size="small"
                                        name="rawMaterialId"
                                        value={rawMaterialId}
                                        style={{ width: "250px" }}
                                        onBlur={(e) => handleValidity(1)}
                                        onChange={(event, newValue) => {
                                          if (!newValue?.Id) {
                                            setRawMaterialCheck(null);
                                            return;
                                          }
                                          setrawMaterialId(newValue);
                                          props.values.rawMaterialId =
                                            newValue?.Id;
                                          setRawMaterialCheck(5);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={
                                              <div style={{ float: "left" }}>
                                                <span>Select </span>
                                              </div>
                                            }
                                            error={
                                              valids[1] === 1 &&
                                              rawMaterialCheck === null
                                            }
                                          />
                                        )}
                                      />
                                    </div>
                                    <div className="col">
                                      <Button
                                        variant="outline-info"
                                        style={{
                                          width: "30px",
                                          height: "40px",
                                          float: "left",
                                        }}
                                        onClick={(e) => setOpenMaterial(true)}
                                      >
                                        <h2
                                          style={{
                                            marginTop: "-5px",
                                            marginLeft: "-7px",
                                          }}
                                        >
                                          +
                                        </h2>
                                      </Button>
                                    </div>
                                  </Row>
                                </Col>
                                <Col md={6} className="mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    label="Qty"
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    name="quantity"
                                    onChange={props.handleChange}
                                    value={props.values.quantity}
                                    onBlur={props.handleBlur}
                                    error={
                                      props.touched.quantity &&
                                      props.errors.quantity
                                    }
                                    helperText={
                                      props.touched.quantity &&
                                      props.errors.quantity
                                        ? `${props.errors.quantity}`
                                        : ""
                                    }
                                    style={{ width: "300px", float: "left" }}
                                  />
                                </Col>
                              </Form.Group>
                              <Form.Group
                                as={Row}
                                controlId="formHorizontalUsername"
                              >
                                <Col md={6} className="mb-3">
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="Price"
                                    type="text"
                                    size="small"
                                    name="price"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.price}
                                    error={
                                      props.touched.price && props.errors.price
                                    }
                                    helperText={
                                      props.touched.price && props.errors.price
                                        ? `${props.errors.price}`
                                        : ""
                                    }
                                    style={{ width: "300px", float: "left" }}
                                  />
                                </Col>
                                <Col md={6} className="mb-3">
                                  <Button
                                    style={{
                                      width: "130px",
                                      height: "35px",
                                      float: "left",
                                    }}
                                    variant="outline-primary"
                                    type="submit"
                                  >
                                    <h3
                                      style={{
                                        float: "left",
                                        marginTop: "-6px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      +
                                    </h3>
                                    <h6
                                      style={{
                                        float: "left",
                                        marginTop: "1px",
                                      }}
                                    >
                                      Add To List
                                    </h6>
                                  </Button>
                                </Col>
                              </Form.Group>
                            </Form>

                            <div>
                              <div>
                                <table className="summary-table ">
                                  <thead className="bg-info">
                                    <tr>
                                      <th>Id</th>
                                      <th>Name</th>
                                      <th>Color</th>
                                      <th>Unit</th>
                                      <th>Qty</th>
                                      <th>U.Price</th>
                                      <th>T.Price</th>
                                      <th
                                        style={{
                                          width: "300px",
                                        }}
                                      >
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {rawMaterialValue.map((request, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                          {rawMaterials.map((rawMaterial) =>
                                            rawMaterial.Id ===
                                            parseInt(request.rawMaterialId)
                                              ? rawMaterial.name
                                              : ""
                                          )}
                                        </td>
                                        <td>
                                          {rawMaterials.map((rawMaterial) =>
                                            rawMaterial.Id ===
                                            parseInt(request.rawMaterialId)
                                              ? colors.map((color) =>
                                                  color.Id ===
                                                  parseInt(rawMaterial.colorId)
                                                    ? color.colorName
                                                    : ""
                                                )
                                              : ""
                                          )}
                                        </td>
                                        <td>
                                          {rawMaterials.map((rawMaterial) =>
                                            rawMaterial.Id ===
                                            parseInt(request.rawMaterialId)
                                              ? units.map((unit) =>
                                                  unit.Id ===
                                                  parseInt(rawMaterial.unitId)
                                                    ? unit.unitName
                                                    : ""
                                                )
                                              : ""
                                          )}
                                        </td>
                                        <td>
                                          {openUpdate &&
                                          request.rawMaterialId ===
                                            update.rawMaterialId ? (
                                            <input
                                              type="number"
                                              minLength={1}
                                              maxLength={20}
                                              className="form-control"
                                              required
                                              style={{ width: "100px" }}
                                              value={
                                                update.quantity === ""
                                                  ? request.quantity
                                                  : update.quantity
                                              }
                                              onChange={(e) => {
                                                if (
                                                  parseInt(e.target.value) <
                                                    1 ||
                                                  parseInt(e.target.value) >
                                                    1000
                                                ) {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    quantity: 1,
                                                  }));
                                                } else {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    quantity: parseInt(
                                                      e.target.value
                                                    ),
                                                  }));
                                                }
                                              }}
                                            />
                                          ) : (
                                            request.quantity
                                          )}
                                        </td>
                                        <td>
                                          {openUpdate &&
                                          request.rawMaterialId ===
                                            update.rawMaterialId ? (
                                            <input
                                              type="number"
                                              className={
                                                parseFloat(update.price) < 50
                                                  ? "form-control is-invalid"
                                                  : "form-control"
                                              }
                                              minLength={100}
                                              maxLength={1000000}
                                              required
                                              value={
                                                update.price === ""
                                                  ? request.price
                                                  : update.price
                                              }
                                              style={{
                                                margin: "0",
                                                width: "200px",
                                              }}
                                              onChange={(e) => {
                                                if (
                                                  parseFloat(e.target.value) <
                                                    1 ||
                                                  parseFloat(e.target.value) >
                                                    1000000
                                                ) {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    price: 1,
                                                  }));
                                                } else {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    price: parseFloat(
                                                      e.target.value
                                                    ),
                                                  }));
                                                }

                                                setRawMaterialValue([
                                                  ...rawMaterialValue,
                                                ]);
                                              }}
                                            />
                                          ) : (
                                            request.price
                                          )}
                                        </td>
                                        <td>{request.totalprice}</td>
                                        <td>
                                          {(request.rawMaterialId !==
                                            update.rawMaterialId ||
                                            !openUpdate) && (
                                            <div>
                                              <Button
                                                variant="outline-warning"
                                                className="mr-3"
                                                onClick={(e) => {
                                                  setOpenUpdate(true);
                                                  setUpdate({
                                                    rawMaterialId:
                                                      request.rawMaterialId,
                                                    price: "",
                                                    quantity: "",
                                                  });
                                                }}
                                              >
                                                <div
                                                  className="row "
                                                  style={{
                                                    marginBottom: "-10px",
                                                    marginLeft: "-10px",
                                                  }}
                                                >
                                                  <div className="col">
                                                    <FontAwesomeIcon
                                                      style={{ float: "right" }}
                                                      icon={faPenToSquare}
                                                    />
                                                  </div>
                                                  <div className="col-6">
                                                    <h6
                                                      style={{
                                                        float: "left",
                                                        marginLeft: "-10px",
                                                      }}
                                                    >
                                                      Edit
                                                    </h6>
                                                  </div>
                                                </div>
                                              </Button>
                                              <Button
                                                variant="outline-danger"
                                                onClick={(e) => {
                                                  const newRawMaterial =
                                                    rawMaterialValue.find(
                                                      (i) =>
                                                        parseInt(
                                                          i.rawMaterialId
                                                        ) ===
                                                        parseInt(
                                                          request.rawMaterialId
                                                        )
                                                    );

                                                  const tax = taxs.find(
                                                    (t) =>
                                                      t.Id ===
                                                      newRawMaterial?.taxId
                                                  );
                                                  const priceVat =
                                                    newRawMaterial?.quantity *
                                                    parseFloat(
                                                      newRawMaterial?.price
                                                    ) *
                                                    parseFloat(
                                                      tax?.taxValue / 100
                                                    );
                                                  const newrawMaterialValue =
                                                    rawMaterialValue?.filter(
                                                      (i) =>
                                                        parseInt(
                                                          i.rawMaterialId
                                                        ) !==
                                                        parseInt(
                                                          request.rawMaterialId
                                                        )
                                                    );
                                                  setRawMaterialValue(
                                                    newrawMaterialValue
                                                  );
                                                  price.vat = 0;
                                                  price.total =
                                                    parseFloat(price.total) -
                                                    parseFloat(
                                                      newRawMaterial.price
                                                    ) *
                                                      newRawMaterial.quantity;
                                                  price.grandTotal =
                                                    price.vat + price.total;
                                                }}
                                              >
                                                <div
                                                  className="row"
                                                  style={{
                                                    marginBottom: "-10px",
                                                    marginLeft: "-10px",
                                                  }}
                                                >
                                                  <div className="col">
                                                    <FontAwesomeIcon
                                                      style={{
                                                        float: "right",
                                                        marginRight: "-5px",
                                                      }}
                                                      icon={faTrashCan}
                                                    />
                                                  </div>
                                                  <div className="col-8">
                                                    <h6
                                                      style={{
                                                        float: "left",
                                                        marginLeft: "-3px",
                                                      }}
                                                    >
                                                      Delete
                                                    </h6>
                                                  </div>
                                                </div>
                                              </Button>
                                            </div>
                                          )}

                                          {openUpdate &&
                                            request.rawMaterialId ===
                                              update.rawMaterialId && (
                                              <div>
                                                <Button
                                                  variant="outline-success"
                                                  className="mr-3"
                                                  onClick={(e) => {
                                                    if (
                                                      parseFloat(update.price) <
                                                        50 ||
                                                      parseFloat(update.price) >
                                                        1000000
                                                    ) {
                                                      return;
                                                    }
                                                    price.grandTotal = 0;
                                                    price.total = 0;
                                                    price.vat = 0;
                                                    rawMaterialValue[
                                                      index
                                                    ].price = !!update.price
                                                      ? parseFloat(update.price)
                                                      : parseFloat(
                                                          rawMaterialValue[
                                                            index
                                                          ].price
                                                        );
                                                    rawMaterialValue[
                                                      index
                                                    ].quantity =
                                                      !!update.quantity
                                                        ? parseInt(
                                                            update.quantity
                                                          )
                                                        : parseFloat(
                                                            rawMaterialValue[
                                                              index
                                                            ].quantity
                                                          );
                                                    rawMaterialValue[
                                                      index
                                                    ].totalprice =
                                                      rawMaterialValue[index]
                                                        .price *
                                                      rawMaterialValue[index]
                                                        .quantity;
                                                    setRawMaterialValue([
                                                      ...rawMaterialValue,
                                                    ]);
                                                    update.rawMaterialId = "";
                                                    update.price = "";
                                                    update.quantity = "";
                                                    rawMaterialValue.map(
                                                      (i) => {
                                                        const tax = taxs.find(
                                                          (t) =>
                                                            t.Id === i.taxId
                                                        );
                                                        price.vat = 0;
                                                        price.total =
                                                          parseFloat(
                                                            price.total
                                                          ) +
                                                          parseFloat(i.price) *
                                                            i.quantity;
                                                      }
                                                    );
                                                    price.grandTotal =
                                                      price.total + price.vat;
                                                  }}
                                                >
                                                  <div
                                                    className="row "
                                                    style={{
                                                      marginBottom: "-10px",
                                                      marginLeft: "-20px",
                                                    }}
                                                  >
                                                    <div className="col">
                                                      <FontAwesomeIcon
                                                        style={{
                                                          float: "right",
                                                        }}
                                                        className="fa-regular"
                                                        icon={faFloppyDisk}
                                                      />
                                                    </div>
                                                    <div className="col-6">
                                                      <h6
                                                        style={{
                                                          float: "left",
                                                          marginLeft: "-10px",
                                                        }}
                                                      >
                                                        Save
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </Button>
                                                <Button
                                                  variant="outline-danger"
                                                  onClick={(e) => {
                                                    setOpenUpdate(false);
                                                    setUpdate({
                                                      rawMaterialId: "",
                                                      price: "",
                                                      quantity: "",
                                                    });
                                                  }}
                                                >
                                                  <div
                                                    className="row"
                                                    style={{
                                                      marginBottom: "-10px",
                                                      marginLeft: "-20px",
                                                    }}
                                                  >
                                                    <div className="col">
                                                      <FontAwesomeIcon
                                                        style={{
                                                          float: "right",
                                                          marginRight: "-5px",
                                                        }}
                                                        icon={faXmark}
                                                      />
                                                    </div>
                                                    <div className="col-8">
                                                      <h6
                                                        style={{
                                                          float: "left",
                                                          marginLeft: "-3px",
                                                        }}
                                                      >
                                                        Cancle
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </Button>
                                              </div>
                                            )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <div className="row">
                                  <div className="col"></div>
                                  <div
                                    className="col mt-1"
                                    style={{
                                      textAlign: "right",
                                      marginBottom: "20px",
                                      float: "right",
                                    }}
                                  >
                                    <Form.Group
                                      as={Row}
                                      controlId="formHorizontalUsername"
                                      style={{ width: "500px" }}
                                    >
                                      <Col>
                                        <Form.Label>Total : </Form.Label>
                                      </Col>
                                      <Col>
                                        <Form.Control
                                          id="outlined-basic"
                                          disabled
                                          label="Qty"
                                          variant="outlined"
                                          type="text"
                                          size="small"
                                          name="qty"
                                          value={price.total}
                                          style={{ width: "150px" }}
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      controlId="formHorizontalUsername"
                                      style={{ width: "500px" }}
                                    >
                                      <Col>
                                        <Form.Label>VAT : </Form.Label>
                                      </Col>
                                      <Col>
                                        <Form.Control
                                          id="outlined-basic"
                                          disabled
                                          label="Qty"
                                          variant="outlined"
                                          type="text"
                                          size="small"
                                          name="qty"
                                          value={price.vat}
                                          style={{ width: "150px" }}
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      controlId="formHorizontalUsername"
                                      style={{ width: "500px" }}
                                    >
                                      <Col>
                                        <Form.Label>Grand Total : </Form.Label>
                                      </Col>
                                      <Col>
                                        <Form.Control
                                          id="outlined-basic"
                                          disabled
                                          label="Qty"
                                          variant="outlined"
                                          type="text"
                                          size="small"
                                          name="qty"
                                          value={price.grandTotal}
                                          style={{ width: "150px" }}
                                        />
                                      </Col>
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <Row className="mb-3">
                    <Col>
                      <Button
                        style={{
                          width: "150px",

                          height: "40px",
                        }}
                        variant="outline-primary"
                        onClick={clearData}
                      >
                        <div className="row mt-1">
                          <div className="col">
                            <FontAwesomeIcon
                              style={{ float: "right" }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-9">
                            <h6 style={{ float: "left" }}>Back To List</h6>
                          </div>
                        </div>
                      </Button>
                    </Col>
                    <Col>
                      <Form onSubmit={formik.handleSubmit}>
                        <Button
                          style={{
                            width: "150px",
                            height: "40px",
                          }}
                          variant="outline-primary"
                          type="submit"
                        >
                          <div className="row mt-1">
                            <div className="col">
                              <FontAwesomeIcon
                                style={{ float: "right" }}
                                icon={faFloppyDisk}
                              />
                            </div>
                            <div className="col-7">
                              <h6 style={{ float: "left" }}>Submit</h6>
                            </div>
                          </div>
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </div>
                <Formik
                  initialValues={{
                    fullName: "",
                    tinNumber: "",
                    address: "",
                    phoneNumber: "",
                  }}
                  validationSchema={Yup.object().shape({
                    fullName: Yup.string()
                      .required("Full name is required.")
                      .min(4, "Too Short!")
                      .max(60, "Too Long"),
                    phoneNumber: Yup.string()
                      .matches(
                        /^\d{9,10}$/,
                        "Phone number must be 9 or 10 digits."
                      )
                      .required("Phone number is required."),
                    address: Yup.string().required("Address is required"),
                  })}
                  onSubmit={async (values) => {
                    try {
                      await axios.post(
                        "https://garment.server.highendtradingplc.com/vendor/add",
                        values
                      );
                    } catch (error) {
                      console.log(error);
                    } finally {
                      fetchAllData();
                      setOpen(false);
                    }
                  }}
                >
                  {(props) => (
                    <Modal
                      show={open}
                      onHide={handleOpen}
                      style={{ minWidth: "700px" }}
                    >
                      <Modal.Header closeButton>
                        <span>Purchase / Vendor / Create</span>
                      </Modal.Header>
                      <Modal.Body>
                        <Form
                          className="m-2 ml-5 pl-5"
                          onSubmit={props.handleSubmit}
                        >
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Full Name"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="fullName"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.fullName}
                                isInvalid={
                                  props.touched.fullName &&
                                  props.errors.fullName
                                }
                                style={{ maxWidth: "300px" }}
                              />

                              {props.touched.fullName &&
                                props.errors.fullName && (
                                  <div className="text-danger mt-1">
                                    {props.errors.fullName}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="phoneNumber"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.phoneNumber}
                                isInvalid={
                                  props.touched.phoneNumber &&
                                  props.errors.phoneNumber
                                }
                                style={{ maxWidth: "300px" }}
                              />

                              {props.touched.phoneNumber &&
                                props.errors.phoneNumber && (
                                  <div className="text-danger mt-1">
                                    {props.errors.phoneNumber}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={8}>
                              <TextField
                                id="outlined-basic"
                                label="Address"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="address"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.address}
                                isInvalid={
                                  props.touched.address && props.errors.address
                                }
                                style={{ maxWidth: "300px" }}
                              />

                              {props.touched.address &&
                                props.errors.address && (
                                  <div className="text-danger mt-1">
                                    {props.errors.address}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <Button
                                style={{ width: "62%" }}
                                variant="outline-success"
                                type="submit"
                              >
                                Create
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  )}
                </Formik>
                <Formik
                  initialValues={{
                    name: "",
                    unitId: "",
                    colorId: "",
                    typeId: "",
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Name is required"),
                    colorId: Yup.string().required("Color is required"),
                    unitId: Yup.string().required("Unit is required"),
                    typeId: Yup.string().required("Type is required"),
                  })}
                  onSubmit={async (values) => {
                    try {
                      // const { file } = values;
                      // const formData = new FormData();
                      // formData.append("file", file);
                      // formData.append("name", values.name);
                      // formData.append("typeId", values.typeId);
                      // formData.append("colorId", values.colorId);
                      // formData.append("unitId", values.unitId);
                      const { name } = values;
                      const userExists = rawMaterials.some(
                        (user) => user.name === name
                      );
                      if (userExists) {
                        alert("Item already exists.");
                        return;
                      }
                      await axios.post(
                        `https://garment.server.highendtradingplc.com/rawMaterial/add`,
                        values
                        // {
                        //   headers: {
                        //     "Content-Type": "multipart/form-data",
                        //   },
                        // }
                      );
                      values.name = "";
                      values.unitId = "";
                      values.colorId = "";
                      values.typeId = "";
                      fetchAllData();
                      setOpenMaterial(false);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  {(props) => (
                    <Modal
                      show={openMaterial}
                      onHide={(e) => {
                        setOpenMaterial(false);
                        props.values.name = "";
                        props.values.unitId = "";
                        props.values.colorId = "";
                        props.values.typeId = "";
                      }}
                      style={{ minWidth: "700px" }}
                    >
                      <Modal.Header closeButton>
                        <span>Purchase / / Create</span>
                      </Modal.Header>
                      <Modal.Body>
                        <center>
                          <Form lassName="m-2" onSubmit={props.handleSubmit}>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <TextField
                                  id="outlined-basic"
                                  label="name"
                                  variant="outlined"
                                  type="text"
                                  size="small"
                                  name="name"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.name}
                                  error={
                                    props.touched.name && props.errors.name
                                  }
                                  helperText={
                                    props.touched.name && props.errors.name
                                      ? `${props.errors.name}`
                                      : ""
                                  }
                                  style={{ width: "250px" }}
                                />
                              </Col>
                            </Form.Group>

                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <TextField
                                  id="outlined-basic"
                                  label="Select Type"
                                  variant="outlined"
                                  select
                                  size="small"
                                  name="typeId"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.typeId}
                                  error={
                                    props.touched.typeId && props.errors.typeId
                                  }
                                  helperText={
                                    props.touched.typeId && props.errors.typeId
                                      ? `${props.errors.typeId}`
                                      : ""
                                  }
                                  style={{ width: "250px" }}
                                >
                                  <MenuItem key={null} value={null}>
                                    None
                                  </MenuItem>
                                  {types.map((typ) => {
                                    return (
                                      <MenuItem key={typ.Id} value={typ.Id}>
                                        {typ.typeName}
                                      </MenuItem>
                                    );
                                  })}
                                </TextField>
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <TextField
                                  id="outlined-basic"
                                  label="Select Color"
                                  variant="outlined"
                                  select
                                  size="small"
                                  name="colorId"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.colorId}
                                  error={
                                    props.touched.colorId &&
                                    props.errors.colorId
                                  }
                                  helperText={
                                    props.touched.colorId &&
                                    props.errors.colorId
                                      ? `${props.errors.colorId}`
                                      : ""
                                  }
                                  style={{ width: "250px" }}
                                >
                                  <MenuItem key={null} value={null}>
                                    None
                                  </MenuItem>
                                  {colors?.map((color) => {
                                    return (
                                      <MenuItem key={color.Id} value={color.Id}>
                                        {color.colorName}
                                      </MenuItem>
                                    );
                                  })}
                                </TextField>
                              </Col>
                            </Form.Group>

                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <TextField
                                  id="outlined-basic"
                                  label="Select Unit"
                                  variant="outlined"
                                  select
                                  size="small"
                                  name="unitId"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.unitId}
                                  error={
                                    props.touched.unitId && props.errors.unitId
                                  }
                                  helperText={
                                    props.touched.unitId && props.errors.unitId
                                      ? `${props.errors.unitId}`
                                      : ""
                                  }
                                  style={{ width: "250px" }}
                                >
                                  <MenuItem key={null} value={null}>
                                    None
                                  </MenuItem>
                                  {units?.map((unit) => {
                                    return (
                                      <MenuItem key={unit.Id} value={unit.Id}>
                                        {unit.unitName}
                                      </MenuItem>
                                    );
                                  })}
                                </TextField>
                              </Col>
                            </Form.Group>
                            {/* <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <Row style={{ width: "300px" }}>
                                  <h5
                                    className="col-3"
                                    style={{
                                      marginTop: "5px",
                                      marginLeft: "-2px",
                                    }}
                                  >
                                    <b>Photo</b>
                                  </h5>{" "}
                                  <TextField
                                    className="col"
                                    type="file"
                                    name="file"
                                    size="small"
                                    onChange={(e) =>
                                      props.setFieldValue(
                                        "file",
                                        e.currentTarget.files[0]
                                      )
                                    }
                                    style={{
                                      width: "180px",
                                      marginLeft: "9px",
                                    }}
                                  />
                                </Row>
                              </Col>
                            </Form.Group> */}
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalUsername"
                            >
                              <Col sm={12}>
                                <Button
                                  style={{ width: "300px" }}
                                  variant="outline-primary"
                                  type="submit"
                                >
                                  Create
                                </Button>
                              </Col>
                            </Form.Group>
                          </Form>
                        </center>
                      </Modal.Body>
                    </Modal>
                  )}
                </Formik>
              </div>
            </center>
          </main>
        </div>
      )}
    </div>
  );
}

export default RawPurchasePage;
