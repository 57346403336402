/** @format */
import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik, Formik } from "formik";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Header from "../Header";
import {
  faBackwardStep,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import { MenuItem } from "@mui/material";
import axios from "axios";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as XLSX from "xlsx";
const RawMaterialPage = () => {
  const [open, setOpen] = useState(false);
  const [starts, setStarts] = useState(false);
  const [types, setTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [colors, setColors] = useState([]);

  const [items, setItems] = useState([]);

  const [popup, setPopup] = useState(false);
  const [idvalue, setidvalue] = useState({
    idValue: 0,
  });
  const [data, setData] = React.useState(null);
  const [handle, setHandle] = useState({
    item: true,
    createItem: false,
    addType: false,
    addColor: false,
    addUnit: false,
  });
  const handleAdd = () => {
    setOpen(true);
  };
  const handlePopup = () => {
    setPopup(false);
  };
  const handleOpen = () => {
    setOpen(false);
    setStarts(false);
  };
  const handleItem = () => {
    setHandle((prev) => ({ item: true }));
  };
  const handleType = () => {
    setPopup(true);
    setHandle((prev) => ({ addType: true, createItem: true }));
  };
  const handleColor = () => {
    setPopup(true);
    setHandle((prev) => ({ addColor: true, createItem: true }));
  };
  const handleUnit = () => {
    setPopup(true);
    setHandle((prev) => ({ addUnit: true, createItem: true }));
  };

  const createItem = () => {
    setHandle((prev) => ({ createItem: true }));
  };
  const fetchAllData = async () => {
    try {
      const [type, unit, color, item] = await Promise.all([
        axios.get("https://garment.server.highendtradingplc.com/type"),
        axios.get("https://garment.server.highendtradingplc.com/unit"),
        axios.get("https://garment.server.highendtradingplc.com/color"),
        axios.get("https://garment.server.highendtradingplc.com/rawMaterial"),
      ]);
      setTypes(type.data.filter((t) => t.Id > 3));
      setUnits(unit.data);
      setColors(color.data);
      setItems(item.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    colorId: Yup.string().required("Color is required"),
    unitId: Yup.string().required("Unit is required"),
    // file: Yup.mixed().required("File is Required!!!"),
    typeId: Yup.string().required("Type is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      unitId: "",
      colorId: "",
      typeId: "",
      // file: "",
      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      try {
        const { name } = values;

        const userExists = items.some((user) => user.name === name);
        //
        // const { file } = formik.values;
        // const formData = new FormData();
        // formData.append("file", file);
        // formData.append("name", values.name);
        // formData.append("typeId", values.typeId);
        // formData.append("colorId", values.colorId);
        // formData.append("unitId", values.unitId);
        if (handle.createItem && !handle.addColor && values.id === "") {
          if (userExists) {
            formik.setErrors({
              name: "Item already exists.",
            });
            return;
          }
          alert("Data Add Successfully!");
          await axios.post(
            `https://garment.server.highendtradingplc.com/rawMaterial/add`,
            values
            // {
            // headers: { "Content-Type": "multipart/form-data" },
            // }
          );
          handle.createItem = false;
          handle.item = true;
        } else {
          alert("Data Update Successfully!");
          await axios.put(
            "https://garment.server.highendtradingplc.com/rawMaterial/update/" +
              values.id,
            values
          );
        }
        // } else if (idvalue.idValue !== 0) {
        //   const rawMaterail = items.find((raw) => raw.Id === values.id);
        //   if (values.file === rawMaterail.photo) {
        //     await axios.put(
        //       "https://garment.server.highendtradingplc.com/rawMaterial/update/" + values.id,
        //       values
        //     );
        //   } else {
        //     await axios.put(
        //       "https://garment.server.highendtradingplc.com/rawMaterial/updateFile/" + values.id,
        //       formData,
        //       {
        //         headers: { "Content-Type": "multipart/form-data" },
        //       }
        //     );
        //   }
        // }
      } catch (error) {
        console.log(error);
      } finally {
        fetchAllData();
        handleItem();
        formik.values.colorId = "";
        formik.values.typeId = "";
        formik.values.unitId = "";
        formik.values.name = "";
        formik.values.id = "";
      }
    },
  });
  // const ends = async () => {
  //   try {
  //     let data = [];
  //     items.map((i) => {
  //       const newData = {
  //         name: i.name,
  //         code: i.code,
  //         type: types.find((t) => t.Id === i.typeId)?.typeName,
  //         model: colors.find((t) => t.Id === i.modelId)?.modelName,
  //         unit: units.find((t) => t.Id === i.unitId)?.unitName,
  //         taxType: taxs.find((t) => t.Id === i.taxId)?.taxType,
  //         taxValue: taxs.find((t) => t.Id === i.taxId)?.taxValue,
  //         logicalQuantity: i.logicalQuantity,
  //         physicalQuantity: i.physicalQuantity,
  //         itemType: i.statusId === 1 ? "Service" : "Consumable",
  //       };
  //       data.push(newData);
  //     });
  //     // Create a new workbook and a new worksheet
  //     const wb = XLSX.utils.book_new();
  //     const ws = XLSX.utils.json_to_sheet(data);
  //     XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //     // Write to file and trigger download
  //     XLSX.writeFile(wb, "data.xlsx");
  //     await axios.delete(`https://garment.server.highendtradingplc.com/rawMaterial/deletes`);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     window.location.reload();
  //   }
  // };
  // const start = () => {
  //   setStarts(true);
  // };
  // const handleFileUpload = (e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = (event) => {
  //     const workbook = XLSX.read(event.target.result, { type: "binary" });
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const sheetData = XLSX.utils.sheet_to_json(sheet);
  //     setData(sheetData);
  //   };
  //   reader.readAsBinaryString(file);
  // };
  // const handleInserItem = async () => {
  //   try {
  //     for (let i = 0; i < data.length; ) {
  //       let values = {
  //         name: data[i].name,
  //         code: data[i].code,
  //         typeId: types.find((t) => t.typeName === data[i].type)?.Id,
  //         modelId: colors.find((t) => t.modelName === data[i].model)?.Id,
  //         unitId: units.find((t) => t.unitName === data[i].unit)?.Id,
  //         taxId: taxs.find((t) => t.taxName === data[i].tax)?.Id,
  //         logicalQuantity: data[i].logicalQuantity,
  //         physicalQuantity: data[i].physicalQuantity,
  //         statusId: data[i].itemType === "Service" ? 1 : 2,
  //       };
  //       console.log(values);
  //       const res = await axios.post(`https://garment.server.highendtradingplc.com/rawMaterial/add`, values);
  //       console.log(res);
  //       i = i + 1;
  //     }
  //     fetchAllData();
  //     setStarts(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const handleCSVSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     console.log(data);
  //     let type = [],
  //       model = [],
  //       unit = [],
  //       taxValue = [],
  //       taxType = [];

  //     for (let i = 0; i < data.length; i = i + 1) {
  //       type.push(data[i].type);
  //       model.push(data[i].model);
  //       unit.push(data[i].unit);
  //       if (!!!parseFloat(data[i].taxValue)) {
  //         alert("You insert invalid input");
  //         return;
  //       }
  //       taxValue.push(data[i].taxValue);
  //       taxType.push(data[i].taxType);
  //     }
  //     type = [...new Set(type)];
  //     model = [...new Set(model)];
  //     unit = [...new Set(unit)];
  //     taxValue = [...new Set(taxValue)];
  //     taxType = [...new Set(taxType)];
  //     for (let i = 0; i < type.length; ) {
  //       await axios.post(`https://garment.server.highendtradingplc.com/type/add`, {
  //         typeName: type[i],
  //       });
  //       i = i + 1;
  //     }
  //     for (let i = 0; i < model.length; ) {
  //       await axios.post(`https://garment.server.highendtradingplc.com/model/add`, {
  //         modelName: model[i],
  //       });
  //       i = i + 1;
  //     }
  //     for (let i = 0; i < unit.length; ) {
  //       await axios.post(`https://garment.server.highendtradingplc.com/unit/add`, {
  //         unitName: unit[i],
  //       });
  //       i = i + 1;
  //     }
  //     for (let i = 0; i < taxValue.length; ) {
  //       await axios.post(`https://garment.server.highendtradingplc.com/tax/add`, {
  //         taxType: taxType[i],
  //         taxValue: parseFloat(taxValue[i]),
  //       });
  //       i = i + 1;
  //     }
  //     fetchAllData();
  //     handleInserItem();
  //     console.log(type, model, unit, taxValue);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://garment.server.highendtradingplc.com/rawMaterial/delete/${id}`
      );
      setItems(items.filter((item) => item.Id !== id));
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = async (id) => {
    const item = items.find((i) => i.Id === id);
    formik.values.colorId = item.colorId;
    formik.values.typeId = item.typeId;
    formik.values.unitId = item.unitId;
    formik.values.name = item.name;

    formik.values.id = id;
    setidvalue(id);
    createItem();
  };
  const columnItem = [
    { field: "Id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 200 },

    {
      field: "typeId",
      headerName: "Type",
      width: 150,
      valueGetter: (params) =>
        types.find((type) => type.Id === params)?.typeName || "",
    },
    {
      field: "colorId",
      headerName: "Color",
      width: 150,
      valueGetter: (params) =>
        colors.find((color) => color.Id === params)?.colorName || "",
    },
    {
      field: "unitId",
      headerName: "Unit",
      width: 150,
      valueGetter: (params) =>
        units.find((unit) => unit.Id === params)?.unitName || "",
    },
    { field: "quantity", headerName: "Qty", width: 80 },
    {
      field: "action",
      headerName: "Action",
      width: 220,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const style = {
    backgroundColor: "white",
    borderRadius: "5px 5px 0 0",
    border: "solid 0.05em rgb(181, 181, 181)",
  };
  return (
    <div>
      <div>
        <Header />
      </div>
      <main className="inventory-page" style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div
              className="tab-container "
              style={{
                marginTop: "-20px",
                marginBottom: "-17px",
              }}
            >
              <Link
                className="p-2"
                onClick={(e) => window.location.reload()}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px 5px 0 0",
                  border: "solid 0.05em rgb(181, 181, 181)",
                }}
              >
                Raw Material
              </Link>
              <Link className="p-2" to={`/products`}>
                Product
              </Link>
              <Link className="p-2" to={`/raw_purchase`}>
                Raw Purchase
              </Link>
              <Link className="p-2" to={`/raw_purchase_list`}>
                Purchase List
              </Link>
            </div>
            <hr />
            <div
              className="sub-tab-container"
              style={
                handle.addColor ||
                handle.addTax ||
                handle.addType ||
                handle.addUnit
                  ? { marginBottom: "0" }
                  : {}
              }
            >
              <Link className="pr-2 pl-2" to={`/raw_material/types/${1}`}>
                Type
              </Link>
              <Link className="pr-2 pl-2" to={`/raw_material/types/${3}`}>
                Color
              </Link>
              <Link className="pr-2 pl-2" to={`/raw_material/types/${2}`}>
                Unit
              </Link>
            </div>

            <div className="card card-info card-outline">
              <div className="card-header">
                {!handle.createItem && (
                  <Button
                    variant="outline-info"
                    style={{ width: "150px", height: "35px", float: "left" }}
                    onClick={createItem}
                  >
                    <h3
                      style={{
                        float: "left",
                        marginTop: "-6px",
                        marginRight: "5px",
                      }}
                    >
                      +
                    </h3>
                    <h6 style={{ float: "left", marginTop: "1px" }}>
                      {" "}
                      Create New
                    </h6>
                  </Button>
                )}
                {!handle.createItem && (
                  <Button
                    className="ml-2"
                    variant="outline-info"
                    style={{ width: "120px", height: "35px", float: "right" }}
                    //   onClick={ends}
                  >
                    <div
                      className="row"
                      style={{
                        marginBottom: "-10px",
                        marginLeft: "-10px",
                      }}
                    >
                      <div className="col">
                        <FontAwesomeIcon
                          style={{ float: "right", marginRight: "-7px" }}
                          icon={faFileExport}
                        />
                      </div>
                      <div className="col-7">
                        <h6
                          style={{
                            float: "left",
                            marginLeft: "-3px",
                          }}
                        >
                          End
                        </h6>
                      </div>
                    </div>
                  </Button>
                )}
                {!handle.createItem && (
                  <Button
                    className="mr-2"
                    variant="outline-info"
                    style={{ width: "100px", height: "35px", float: "right" }}
                    //   onClick={start}
                  >
                    <h3
                      style={{
                        float: "left",
                        marginTop: "-6px",
                        marginRight: "5px",
                      }}
                    >
                      +
                    </h3>
                    <h6 style={{ float: "left", marginTop: "1px" }}>Begin</h6>
                  </Button>
                )}

                {handle.createItem && (
                  <div>
                    <h5 style={{ float: "left" }}>
                      {formik.values.id === "" ? "Create New" : "Update"} Item
                    </h5>
                    <Button
                      onClick={handleItem}
                      variant="outline-success"
                      className="m-1"
                      style={{ float: "right" }}
                    >
                      <div
                        className="row"
                        style={{
                          marginBottom: "-10px",
                          marginLeft: "-10px",
                        }}
                      >
                        <div className="col">
                          <FontAwesomeIcon
                            style={{ float: "right", marginRight: "-1px" }}
                            icon={faBackwardStep}
                          />
                        </div>
                        <div className="col-7">
                          <h6
                            style={{
                              float: "left",
                              marginLeft: "-10px",
                            }}
                          >
                            Back
                          </h6>
                        </div>
                      </div>
                    </Button>
                  </div>
                )}
              </div>
              <div className="card-body">
                {handle.createItem && (
                  <div>
                    <div
                      className="nav-bra pl-3 ml-1 p-2"
                      style={{
                        backgroundColor: "rgb(235, 235, 235)",
                      }}
                    >
                      <span>Home / Raw Material / Create</span>
                    </div>
                    <hr />
                  </div>
                )}
                {handle.item && (
                  <div>
                    <div
                      className="nav-bra pl-3 ml-1 p-2"
                      style={{
                        backgroundColor: "rgb(235, 235, 235)",
                        height: "40px",
                      }}
                    >
                      <span style={{ float: "left" }}>Raw Material / Home</span>
                    </div>
                    <hr />
                  </div>
                )}
                {handle.item && (
                  <DataGrid
                    rows={items}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    columns={columnItem}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    initialState={{
                      ...items.initialState,
                      pagination: {
                        paginationModel: { pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10, 15]}
                    getRowId={(row) => row.Id}
                  />
                )}
                {handle.createItem && (
                  <div>
                    <center>
                      <div>
                        <Form
                          lassName="m-2 ml-5 pl-5"
                          onSubmit={formik.handleSubmit}
                        >
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <TextField
                                id="outlined-basic"
                                label="name"
                                variant="outlined"
                                type="text"
                                size="small"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                error={
                                  formik.touched.name && formik.errors.name
                                }
                                helperText={
                                  formik.touched.name && formik.errors.name
                                    ? `${formik.errors.name}`
                                    : ""
                                }
                                style={{ width: "300px" }}
                              />
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <TextField
                                id="outlined-basic"
                                label="Select Type"
                                variant="outlined"
                                select
                                size="small"
                                name="typeId"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.typeId}
                                error={
                                  formik.touched.typeId && formik.errors.typeId
                                }
                                helperText={
                                  formik.touched.typeId && formik.errors.typeId
                                    ? `${formik.errors.typeId}`
                                    : ""
                                }
                                style={{ width: "250px" }}
                              >
                                <MenuItem key={null} value={null}>
                                  None
                                </MenuItem>
                                {types.map((typ) => {
                                  return (
                                    <MenuItem key={typ.Id} value={typ.Id}>
                                      {typ.typeName}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                              <Button
                                variant="outline-info"
                                //onClick={openAddModal}
                                style={{
                                  width: "30px",
                                  height: "40px",
                                  marginLeft: "20px",
                                }}
                                onClick={handleType}
                              >
                                <h2
                                  style={{
                                    marginTop: "-5px",
                                    marginLeft: "-7px",
                                  }}
                                >
                                  +
                                </h2>
                              </Button>
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <TextField
                                id="outlined-basic"
                                label="Select Color"
                                variant="outlined"
                                select
                                size="small"
                                name="colorId"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.colorId}
                                error={
                                  formik.touched.colorId &&
                                  formik.errors.colorId
                                }
                                helperText={
                                  formik.touched.colorId &&
                                  formik.errors.colorId
                                    ? `${formik.errors.colorId}`
                                    : ""
                                }
                                style={{ width: "250px" }}
                              >
                                <MenuItem key={null} value={null}>
                                  None
                                </MenuItem>
                                {colors.map((color) => {
                                  return (
                                    <MenuItem key={color.Id} value={color.Id}>
                                      {color.colorName}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                              <Button
                                variant="outline-info"
                                //onClick={openAddModal}
                                style={{
                                  width: "30px",
                                  height: "40px",
                                  marginLeft: "20px",
                                }}
                                onClick={handleColor}
                              >
                                <h2
                                  style={{
                                    marginTop: "-5px",
                                    marginLeft: "-7px",
                                  }}
                                >
                                  +
                                </h2>
                              </Button>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <TextField
                                id="outlined-basic"
                                label="Select Unit"
                                variant="outlined"
                                select
                                size="small"
                                name="unitId"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.unitId}
                                error={
                                  formik.touched.unitId && formik.errors.unitId
                                }
                                helperText={
                                  formik.touched.unitId && formik.errors.unitId
                                    ? `${formik.errors.unitId}`
                                    : ""
                                }
                                style={{ width: "250px" }}
                              >
                                <MenuItem key={null} value={null}>
                                  None
                                </MenuItem>
                                {units.map((unit) => {
                                  return (
                                    <MenuItem key={unit.Id} value={unit.Id}>
                                      {unit.unitName}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                              <Button
                                variant="outline-info"
                                //onClick={openAddModal}
                                style={{
                                  width: "30px",
                                  height: "40px",
                                  marginLeft: "20px",
                                }}
                                onClick={handleUnit}
                              >
                                <h2
                                  style={{
                                    marginTop: "-5px",
                                    marginLeft: "-7px",
                                  }}
                                >
                                  +
                                </h2>
                              </Button>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <Row style={{ width: "300px" }}>
                                <h5
                                  className="col-3"
                                  style={{
                                    marginTop: "5px",
                                    marginLeft: "-2px",
                                  }}
                                >
                                  <b>Photo</b>
                                </h5>{" "}
                                <TextField
                                  className="col"
                                  type="file"
                                  name="file"
                                  size="small"
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "file",
                                      e.currentTarget.files[0]
                                    )
                                  }
                                  style={{ width: "180px", marginLeft: "9px" }}
                                />
                              </Row>
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <Button
                                style={{ width: "300px" }}
                                variant="outline-primary"
                                type="submit"
                              >
                                {formik.values.id === "" ? "Create" : "Update"}
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </div>
                    </center>
                  </div>
                )}
              </div>
            </div>
            <Modal
              style={{ marginTop: "80px" }}
              show={starts}
              onHide={handleOpen}
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {" "}
                <Form
                  className=""
                  // onSubmit={handleCSVSubmit}
                  encType="multipart/form-data"
                >
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Form.Label column sm={4}>
                      Import Data
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type="file"
                        placeholder="Class Name"
                        name="file"
                        //  onChange={handleFileUpload}
                        style={{ maxWidth: "300px" }}
                      />
                    </Col>

                    <Col sm="3">
                      <Button variant="outline-success" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </Modal.Body>
            </Modal>

            <Modal
              show={popup}
              onHide={handlePopup}
              style={{ minWidth: "700px" }}
            >
              <Modal.Body>
                {handle.addType && (
                  <Formik
                    initialValues={{
                      typeName: "",
                    }}
                    validationSchema={Yup.object().shape({
                      typeName: Yup.string()
                        .required("Type is required")
                        .min(2, "Too Short!!!")
                        .max(20, "Too High!!!"),
                    })}
                    onSubmit={async (values) => {
                      console.log(values);
                      try {
                        await axios.post(
                          `https://garment.server.highendtradingplc.com/type/add`,
                          values
                        );

                        setPopup(false);
                      } catch (error) {
                        console.log(error);
                      } finally {
                        fetchAllData();
                      }
                    }}
                  >
                    {(props) => (
                      <Form
                        className="m-2 ml-5 pl-5"
                        onSubmit={props.handleSubmit}
                      >
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              label="Type Name"
                              variant="outlined"
                              type="text"
                              name="typeName"
                              size="small"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.typeName}
                              error={
                                props.touched.typeName && props.errors.typeName
                              }
                              helperText={
                                props.touched.typeName && props.errors.typeName
                                  ? `${props.errors.typeName}`
                                  : ""
                              }
                              style={{ maxWidth: "300px" }}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <Button
                              style={{ width: "62%" }}
                              variant="outline-primary"
                              type="submit"
                            >
                              Create
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    )}
                  </Formik>
                )}
                {handle.addColor && (
                  <Formik
                    initialValues={{
                      colorName: "",
                    }}
                    validationSchema={Yup.object().shape({
                      colorName: Yup.string()
                        .required("Color is required")
                        .min(2, "Too Short!!!")
                        .max(20, "Too High!!!"),
                    })}
                    onSubmit={async (values) => {
                      console.log(values);
                      try {
                        await axios.post(
                          `https://garment.server.highendtradingplc.com/color/add`,
                          values
                        );
                        setPopup(false);
                      } catch (error) {
                        console.log(error);
                      } finally {
                        fetchAllData();
                      }
                    }}
                  >
                    {(props) => (
                      <Form
                        className="m-2 ml-5 pl-5"
                        onSubmit={props.handleSubmit}
                      >
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              label="Color Name"
                              variant="outlined"
                              type="text"
                              name="colorName"
                              size="small"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.colorName}
                              error={
                                props.touched.colorName &&
                                props.errors.colorName
                              }
                              helperText={
                                props.touched.colorName &&
                                props.errors.colorName
                                  ? `${props.errors.colorName}`
                                  : ""
                              }
                              style={{ maxWidth: "300px" }}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <Button
                              style={{ width: "62%" }}
                              variant="outline-primary"
                              type="submit"
                            >
                              Create
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    )}
                  </Formik>
                )}
                {handle.addUnit && (
                  <Formik
                    initialValues={{
                      unitName: "",
                    }}
                    validationSchema={Yup.object().shape({
                      unitName: Yup.string()
                        .required("Unit is required")
                        .min(2, "Too Short!!!")
                        .max(20, "Too High!!!"),
                    })}
                    onSubmit={async (values) => {
                      console.log(values);
                      try {
                        await axios.post(
                          `https://garment.server.highendtradingplc.com/unit/add`,
                          values
                        );
                        setPopup(false);
                      } catch (error) {
                        console.log(error);
                      } finally {
                        fetchAllData();
                      }
                    }}
                  >
                    {(props) => (
                      <Form
                        className="m-2 ml-5 pl-5"
                        onSubmit={props.handleSubmit}
                      >
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              label="Unit Name"
                              variant="outlined"
                              type="text"
                              name="unitName"
                              size="small"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.unitName}
                              error={
                                props.touched.unitName && props.errors.unitName
                              }
                              helperText={
                                props.touched.unitName && props.errors.unitName
                                  ? `${props.errors.unitName}`
                                  : ""
                              }
                              style={{ maxWidth: "300px" }}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <Button
                              style={{ width: "62%" }}
                              variant="outline-primary"
                              type="submit"
                            >
                              Create
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    )}
                  </Formik>
                )}
              </Modal.Body>
            </Modal>
          </div>
        </center>
      </main>
    </div>
  );
};

export default RawMaterialPage;
