/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Box, TextField } from "@mui/material";
import { faBackwardStep } from "@fortawesome/free-solid-svg-icons";
const StatusOrder = () => {
  const [sales, setSales] = useState([]);
  const [customers, setCustomer] = useState([]);
  const [allSales, setAllSales] = useState([]);
  const [oneCustomer, setOneCustomer] = useState(null);
  const [users, setUsers] = useState([]);
  const [statusOrders, setStatusOrders] = useState([]);
  const [productionOrder, setProductionOrder] = useState([]);
  const [allProductionOrder, setAllProductionOrder] = useState([]);
  const [products, setProducts] = useState([]);
  const [newQuality, setNewQuality] = useState([]);
  const [open, setOpen] = useState(false);
  const [salesDate, setSalesDate] = useState({
    start: new Date(new Date().setUTCDate(new Date().getUTCDate() - 1)),
    end: new Date(new Date().setUTCDate(new Date().getUTCDate() + 1)),
  });
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    const [sales, customer, statusOrder, productionOrder, product] =
      await Promise.all([
        axios.get(
          "https://garment.server.highendtradingplc.com/productionOrderRef"
        ),
        axios.get("https://garment.server.highendtradingplc.com/customer"),
        axios.get("https://garment.server.highendtradingplc.com/statusOrder"),
        axios.get(
          "https://garment.server.highendtradingplc.com/productionOrder"
        ),
        axios.get("https://garment.server.highendtradingplc.com/products"),
      ]);
    setAllSales(statusOrder.data);
    setCustomer(customer.data);
    setStatusOrders(sales.data);
    setAllProductionOrder(productionOrder.data);
    setProducts(product.data);
    let data = [];
    statusOrder.data?.map((qua) => {
      const date = new Date(qua.date);
      if (
        (date.getUTCFullYear() > salesDate.start.getUTCFullYear() &&
          date.getUTCFullYear() < salesDate.end.getUTCFullYear()) ||
        (date.getUTCFullYear() >= salesDate.start.getUTCFullYear() &&
          date.getUTCMonth() > salesDate.start.getUTCMonth() &&
          date.getUTCFullYear() <= salesDate.end.getUTCFullYear() &&
          date.getUTCMonth() < salesDate.end.getUTCMonth()) ||
        (date.getUTCFullYear() >= salesDate.start.getUTCFullYear() &&
          date.getUTCMonth() >= salesDate.start.getUTCMonth() &&
          date.getUTCDate() >= salesDate.start.getUTCDate() &&
          date.getUTCFullYear() <= salesDate.end.getUTCFullYear() &&
          date.getUTCMonth() <= salesDate.end.getUTCMonth() &&
          date.getUTCDate() <= salesDate.end.getUTCDate())
      ) {
        data.push(qua);
      }
      setSales(data);
    });
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const defaultProps = {
    options: customers,
    getOptionLabel: (option) => option.fullName,
  };
  const handleView = (row) => {
    const order = allProductionOrder.filter(
      (p) => p.productionOrderRefId === row.id
    );
    setProductionOrder(order);
    setNewQuality(sales.find((s) => s.Id === row.id));
    setOpen(true);
  };

  const columns = [
    {
      field: "Id",
      headerClassName: "super-app-theme--header",
      headerName: "#",
      width: 100,
    },
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Status ID",
      width: 100,
    },
    {
      field: "fullName",
      headerClassName: "super-app-theme--header",
      headerName: "Customer Name",
      width: 200,
    },
    {
      field: "tinNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Tin",
      width: 150,
    },
    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "Status In",
      width: 150,
    },
    {
      field: "price",
      headerClassName: "super-app-theme--header",
      headerName: "Price",
      width: 150,
    },
    {
      field: "date",
      headerName: "Receive Date",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
    {
      field: "orderDeadlineDate",
      headerName: "Deadline Date",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];
  const rows = sales?.map((sale, index) => {
    const production = statusOrders.find(
      (p) => p.Id === sale.productionOrderRefId
    );
    const customer = customers.find((s) => s.Id === production.customerId);
    return {
      Id: index + 1,
      id: sale?.Id,
      productionOrderRefId: sale.productionOrderRefId,
      fullName: customer?.fullName,
      tinNumber: customer?.tinNumber,
      price: production?.totalPrice,
      date: production?.orderReceiveDate,
      orderDeadlineDate: production?.orderDeadlineDate,
      status:
        sale.status === 1
          ? "Raw List"
          : sale.status === 2
          ? "Store"
          : sale.status === 3
          ? "Production"
          : sale.status === 4
          ? "Quality Checker"
          : "Sales",
    };
  });
  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            {/* <div
              className="tab-container "
              style={{
                marginTop: "-20px",
                marginBottom: "-5px",
              }}
            >
              <Link className="p-2" to={`/production_order_report`}>
                Productions Order
              </Link>
              <Link className="p-2">Sales</Link>
              <Link className="p-2" to={`/raw_purchase_report`}>
                Raw Purchase
              </Link>

              <Link className="p-2" to={`/raw_order_report`}>
                Raw Order
              </Link>
              <Link className="p-2" to={`/quality_checker_report`}>
                Quality Check
              </Link>
              <Link
                className="p-2"
                to={`/status_report`}
                onClick={(e) => window.location.reload()}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px 5px 0 0",
                  border: "solid 0.05em rgb(181, 181, 181)",
                }}
              >
                Status
              </Link>
            </div> */}
            <div className="card card-info card-outline">
              {open && (
                <div className="card-header">
                  <Button
                    onClick={(e) => {
                      setOpen(false);
                    }}
                    variant="outline-success"
                    className="m-1"
                    style={{ float: "right" }}
                  >
                    <div
                      className="row"
                      style={{
                        marginBottom: "-10px",
                        marginLeft: "-10px",
                      }}
                    >
                      <div className="col">
                        <FontAwesomeIcon
                          style={{ float: "right", marginRight: "-1px" }}
                          icon={faBackwardStep}
                        />
                      </div>
                      <div className="col-7">
                        <h6
                          style={{
                            float: "left",
                            marginLeft: "-10px",
                          }}
                        >
                          Back
                        </h6>
                      </div>
                    </div>
                  </Button>
                </div>
              )}

              {!open && (
                <div className="card-body">
                  <div
                    className="nav-bra pl-3 ml-1 p-2 "
                    style={{
                      backgroundColor: "rgb(235, 235, 235)",
                      height: "40px ",
                    }}
                  >
                    <span style={{ float: "left" }}>Home / Order Status</span>
                  </div>
                  <br />
                  <div style={{ border: "solid 1px #ffb71b" }}></div>
                  <br />
                  <Form>
                    <Form.Group as={Row}>
                      <Col lg={3} className="mb-2">
                        <Autocomplete
                          {...defaultProps}
                          id="controlled-demo"
                          size="small"
                          value={oneCustomer}
                          onChange={(event, newValue) => {
                            setOneCustomer(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Customer" />
                          )}
                        />
                      </Col>
                      <Col lg={4} className="mb-2">
                        <Form.Label
                          style={{
                            margin: "7px 5px 0 0",
                          }}
                        >
                          From
                        </Form.Label>
                        <TextField
                          type="date"
                          size="small"
                          style={{ width: "200px" }}
                          onChange={(event) => {
                            if (
                              new Date() >= new Date(event.target.value) &&
                              new Date(salesDate.end) >=
                                new Date(event.target.value)
                            ) {
                              salesDate.start = new Date(event.target.value);
                            }
                          }}
                        />
                      </Col>
                      <Col lg={4} className="mb-2 ">
                        <Form.Label
                          style={{
                            margin: "7px 27px 0 0",
                          }}
                        >
                          To
                        </Form.Label>
                        <TextField
                          size="small"
                          type="date"
                          style={{ width: "200px" }}
                          onChange={(event) => {
                            if (new Date() >= new Date(event.target.value)) {
                              salesDate.end = new Date(event.target.value);
                            }
                          }}
                        />
                      </Col>
                      <Col lg={1}>
                        <center>
                          <Button
                            onClick={(e) => {
                              let data = [];
                              if (oneCustomer === null) {
                                allSales?.map((qua) => {
                                  const date = new Date(qua.date);
                                  if (
                                    (date.getUTCFullYear() >
                                      salesDate.start.getUTCFullYear() &&
                                      date.getUTCFullYear() <
                                        salesDate.end.getUTCFullYear()) ||
                                    (date.getUTCFullYear() >=
                                      salesDate.start.getUTCFullYear() &&
                                      date.getUTCMonth() >
                                        salesDate.start.getUTCMonth() &&
                                      date.getUTCFullYear() <=
                                        salesDate.end.getUTCFullYear() &&
                                      date.getUTCMonth() <
                                        salesDate.end.getUTCMonth()) ||
                                    (date.getUTCFullYear() >=
                                      salesDate.start.getUTCFullYear() &&
                                      date.getUTCMonth() >=
                                        salesDate.start.getUTCMonth() &&
                                      date.getUTCDate() >=
                                        salesDate.start.getUTCDate() &&
                                      date.getUTCFullYear() <=
                                        salesDate.end.getUTCFullYear() &&
                                      date.getUTCMonth() <=
                                        salesDate.end.getUTCMonth() &&
                                      date.getUTCDate() <=
                                        salesDate.end.getUTCDate())
                                  ) {
                                    data.push(qua);
                                  }
                                });
                              } else {
                                allSales?.map((qua) => {
                                  const date = new Date(qua.date);
                                  if (
                                    (date.getUTCFullYear() >
                                      salesDate.start.getUTCFullYear() &&
                                      date.getUTCFullYear() <
                                        salesDate.end.getUTCFullYear()) ||
                                    (date.getUTCFullYear() >=
                                      salesDate.start.getUTCFullYear() &&
                                      date.getUTCMonth() >
                                        salesDate.start.getUTCMonth() &&
                                      date.getUTCFullYear() <=
                                        salesDate.end.getUTCFullYear() &&
                                      date.getUTCMonth() <
                                        salesDate.end.getUTCMonth()) ||
                                    (date.getUTCFullYear() >=
                                      salesDate.start.getUTCFullYear() &&
                                      date.getUTCMonth() >=
                                        salesDate.start.getUTCMonth() &&
                                      date.getUTCDate() >=
                                        salesDate.start.getUTCDate() &&
                                      date.getUTCFullYear() <=
                                        salesDate.end.getUTCFullYear() &&
                                      date.getUTCMonth() <=
                                        salesDate.end.getUTCMonth() &&
                                      date.getUTCDate() <=
                                        salesDate.end.getUTCDate() &&
                                      qua.customerId === oneCustomer.Id)
                                  ) {
                                    data.push(qua);
                                  }
                                });
                              }

                              setSales(data);
                            }}
                            variant="outline-info"
                          >
                            Search
                          </Button>
                        </center>
                      </Col>
                    </Form.Group>
                  </Form>
                  <hr />
                  <Box
                    sx={{
                      height: 500,
                      width: "100%",
                      "& .super-app-theme--header": {
                        backgroundColor: "#1de5ec",
                        color: "white",
                      },
                    }}
                  >
                    <DataGrid
                      rows={rows}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      columns={columns}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      pageSize={5}
                      getRowId={(row) => row.Id}
                      onCellClick={(raw) => handleView(raw.row)}
                    />
                  </Box>
                </div>
              )}
              {open && (
                <div>
                  <div
                    className="nav-bra pl-3 ml-1 p-2 "
                    style={{
                      backgroundColor: "rgb(235, 235, 235)",
                    }}
                  >
                    <span>
                      Status In{" "}
                      <b>
                        {newQuality?.status === 1
                          ? "Raw List"
                          : newQuality.status === 2
                          ? "Store"
                          : newQuality.status === 3
                          ? "Production"
                          : newQuality.status === 4
                          ? "Quality Checker"
                          : newQuality.status === 5
                          ? "Sales"
                          : "Finished"}
                      </b>
                    </span>
                  </div>
                  <hr />
                  <div>
                    <div>
                      <div className=" ml-1 mr-1">
                        <div className="row">
                          {productionOrder.map((rawOrder) => (
                            <div className="col-md-6 col-lg-4 mb-2 ">
                              <div
                                className="row p-3"
                                style={{
                                  width: "290px",
                                  height: "250px",
                                  borderRadius: "10px",
                                  background: "rgb(235, 235, 235)",
                                }}
                              >
                                <div className="mt-3 col-6">
                                  <img
                                    src={`https://garment.server.highendtradingplc.com/${
                                      products.find(
                                        (p) => p.Id === rawOrder.productId
                                      ).photo
                                    }`}
                                    style={{
                                      width: "120px",
                                      height: "120px",
                                    }}
                                    alt="Photo"
                                  />
                                </div>
                                <div className="mt-2 mb-3  col-6" style={{}}>
                                  {!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      p.typeId === 1
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        {" "}
                                        <b>{`የሸሚዝ ቁመት`}:</b>{" "}
                                        <span>{rawOrder.scale4}</span>
                                      </div>
                                    </>
                                  )}
                                  {!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      p.typeId === 2
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        {" "}
                                        <b>{`ከወገብ ቁመት`}:</b>{" "}
                                        <span>{rawOrder.scale4}</span>
                                      </div>
                                    </>
                                  )}
                                  {!!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      (p.typeId === 2 || p.typeId === 1)
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        {" "}
                                        <b>{`ቁመት`}:</b>{" "}
                                        <span>{rawOrder.scale4}</span>
                                      </div>
                                    </>
                                  )}
                                  {!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      (p.typeId === 2 || p.typeId === 1)
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        {" "}
                                        <b>{`ወገብ ዙሪያ`}:</b>{" "}
                                        <span>{rawOrder.scale4}</span>
                                      </div>
                                    </>
                                  )}
                                  {!!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      (p.typeId === 2 || p.typeId === 1)
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        {" "}
                                        <b>{`ወገብ`}:</b>{" "}
                                        <span>{rawOrder.scale4}</span>
                                      </div>
                                    </>
                                  )}
                                  {!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      (p.typeId === 2 || p.typeId === 1)
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        {" "}
                                        <b>{`ትክሻ`}:</b>{" "}
                                        <span>{rawOrder.scale4}</span>
                                      </div>
                                    </>
                                  )}
                                  {!!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      (p.typeId === 2 || p.typeId === 1)
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        {" "}
                                        <b>{`ታፋ`}:</b>{" "}
                                        <span>{rawOrder.scale4}</span>
                                      </div>
                                    </>
                                  )}

                                  {!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      (p.typeId === 2 || p.typeId === 1)
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        {" "}
                                        <b>{`የእጅ ቁመት`}:</b>{" "}
                                        <span>{rawOrder.scale4}</span>
                                      </div>
                                    </>
                                  )}
                                  {!!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      (p.typeId === 2 || p.typeId === 1)
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        {" "}
                                        <b>{`ዳሌ`}:</b>{" "}
                                        <span>{rawOrder.scale4}</span>
                                      </div>
                                    </>
                                  )}
                                  {!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      p.typeId === 2
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        <b>{`ጡት ዙሪያ`}:</b>{" "}
                                        <span>{rawOrder.scale5}</span>
                                      </div>
                                    </>
                                  )}
                                  {!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      p.typeId === 1
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        <b>{`ደረት ዙሪያ`}:</b>{" "}
                                        <span>{rawOrder.scale5}</span>
                                      </div>
                                    </>
                                  )}
                                  {!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      p.typeId === 2
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        <b>{`ከትክሻ ወገብ`}:</b>{" "}
                                        <span>{rawOrder.scale6}</span>
                                      </div>
                                    </>
                                  )}
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>Quantity:</b>{" "}
                                    <span>{rawOrder.quantity}</span>
                                  </div>
                                </div>
                                <div style={{ float: "none" }} className="">
                                  {!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      p.typeId === 3
                                  ) && (
                                    <>
                                      <br />
                                    </>
                                  )}
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "-20px",
                                    }}
                                    className=""
                                  >
                                    <b className="">Name:</b>
                                    <span className="">
                                      {
                                        products.find(
                                          (p) => p.Id === rawOrder.productId
                                        )?.name
                                      }
                                    </span>
                                  </div>
                                  <br />
                                  {/* <div
                                          style={{
                                            float: "left",
                                            marginTop: "-20px",
                                          }}
                                          className=""
                                        >
                                          <b className="">Type:</b>{" "}
                                          <span className="">
                                            {
                                              types.find(
                                                (t) =>
                                                  t.Id ===
                                                  products.find(
                                                    (p) =>
                                                      p.Id ===
                                                      rawOrder.productId
                                                  )?.typeId
                                              )?.typeName
                                            }
                                          </span>
                                        </div> */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};
export default StatusOrder;
