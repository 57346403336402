/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Modal, Form, Row } from "react-bootstrap";
import { Box, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackwardStep } from "@fortawesome/free-solid-svg-icons";
const RawPurchaseReport = () => {
  const [purchasesRef, setPurchasesRef] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [newPurchases, setNewPurchases] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [types, setTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [colors, setColors] = useState([]);
  const [allPurchaseRef, setAllPurchaseRef] = useState([]);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [open, setOpen] = useState(false);
  const [purcahseDate, setPurcahseDate] = useState({
    start: new Date(new Date().setUTCDate(new Date().getUTCDate() - 1)),
    end: new Date(new Date().setUTCDate(new Date().getUTCDate() + 1)),
  });
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    try {
      const [purchaseRef, purchase, vendor, rawMaterial, type, unit, color] =
        await Promise.all([
          axios.get(
            "https://garment.server.highendtradingplc.com/rawPurchaseRef"
          ),
          axios.get("https://garment.server.highendtradingplc.com/rawPurchase"),
          axios.get("https://garment.server.highendtradingplc.com/vendor"),
          axios.get("https://garment.server.highendtradingplc.com/rawMaterial"),
          axios.get("https://garment.server.highendtradingplc.com/type"),
          axios.get("https://garment.server.highendtradingplc.com/unit"),
          axios.get("https://garment.server.highendtradingplc.com/color"),
        ]);
      setTypes(type.data);
      setUnits(unit.data);
      setColors(color.data);
      setAllPurchaseRef(purchaseRef.data);
      setVendors(vendor.data);
      setPurchases(purchase.data);
      setRawMaterials(rawMaterial.data);
      let data = [];
      purchaseRef.data?.map((qua) => {
        const date = new Date(qua.date);

        if (
          (date.getUTCFullYear() > purcahseDate.start.getUTCFullYear() &&
            date.getUTCFullYear() < purcahseDate.end.getUTCFullYear()) ||
          (date.getUTCFullYear() >= purcahseDate.start.getUTCFullYear() &&
            date.getUTCMonth() > purcahseDate.start.getUTCMonth() &&
            date.getUTCFullYear() <= purcahseDate.end.getUTCFullYear() &&
            date.getUTCMonth() < purcahseDate.end.getUTCMonth()) ||
          (date.getUTCFullYear() >= purcahseDate.start.getUTCFullYear() &&
            date.getUTCMonth() >= purcahseDate.start.getUTCMonth() &&
            date.getUTCDate() >= purcahseDate.start.getUTCDate() &&
            date.getUTCFullYear() <= purcahseDate.end.getUTCFullYear() &&
            date.getUTCMonth() <= purcahseDate.end.getUTCMonth() &&
            date.getUTCDate() <= purcahseDate.end.getUTCDate())
        ) {
          data.push(qua);
        }
        setPurchasesRef(data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const handleView = (row) => {
    const order = purchases.filter((p) => p.rawPurchaseRefId === row.id);
    setNewPurchases(order);
    setOpen(true);
  };
  const purchaseColumns = [
    {
      field: "Id",
      headerName: "Id",
      width: 100,
    },
    {
      field: "id",
      headerName: "Purchase ID",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "rawMaterial",
      headerName: "Raw Material",
      width: 150,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
    },
    {
      field: "qty",
      headerName: "Qty",
      width: 100,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      width: 100,
    },
  ];

  const purchaseRows = newPurchases.map((p, index) => {
    const rawMaterial = rawMaterials.find((r) => r.Id === p.rawMaterialId);
    return {
      Id: p.Id,
      id: p.rawPurchaseRefId,
      rawMaterial: rawMaterial.name,
      price: p.price,
      qty: p.quantity,
      totalPrice: parseFloat(p.price) * parseFloat(p.quantity),
    };
  });
  const columns = [
    {
      field: "Id",
      headerName: "#",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "id",
      headerName: "PurchaseID",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fullName",
      headerName: "Vendor Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];

  const rows = purchasesRef?.map((purchase, index) => {
    const vendor = vendors.find((s) => s.Id === purchase.vendorId);
    return {
      Id: index + 1,
      id: purchase.Id,
      fullName: vendor.fullName,
      price: purchase.totalPrice,
      date: purchase.date,
    };
  });

  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div
              className="tab-container "
              style={{
                marginTop: "-20px",
                marginBottom: "-5px",
              }}
            >
              <Link className="p-2" to={`/production_order_report`}>
                Productions Order
              </Link>
              <Link className="p-2" to={`/sales_report`}>
                Sales
              </Link>
              <Link
                className="p-2"
                onClick={(e) => window.location.reload()}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px 5px 0 0",
                  border: "solid 0.05em rgb(181, 181, 181)",
                }}
              >
                Raw Purchase
              </Link>

              <Link className="p-2" to={`/raw_order_report`}>
                Raw Order
              </Link>
              <Link className="p-2" to={`/quality_checker_report`}>
                Quality Check
              </Link>
            </div>
            <div className="card card-info card-outline">
              {open && (
                <div className="card-header">
                  <Button
                    onClick={(e) => {
                      setOpen(false);
                    }}
                    variant="outline-success"
                    className="m-1"
                    style={{ float: "right" }}
                  >
                    <div
                      className="row"
                      style={{
                        marginBottom: "-10px",
                        marginLeft: "-10px",
                      }}
                    >
                      <div className="col">
                        <FontAwesomeIcon
                          style={{ float: "right", marginRight: "-1px" }}
                          icon={faBackwardStep}
                        />
                      </div>
                      <div className="col-7">
                        <h6
                          style={{
                            float: "left",
                            marginLeft: "-10px",
                          }}
                        >
                          Back
                        </h6>
                      </div>
                    </div>
                  </Button>
                </div>
              )}
              <div className="card-body">
                <div
                  className="nav-bra pl-3 ml-1 p-2 "
                  style={{
                    backgroundColor: "rgb(235, 235, 235)",
                    height: "40px",
                  }}
                >
                  <span style={{ float: "left" }}>
                    Home / Raw Purchase Report
                  </span>
                </div>
                <br />
                <div style={{ border: "solid 1px #ffb71b" }}></div>
                <br />
                {!open && (
                  <div>
                    <Form>
                      <Form.Group as={Row}>
                        <Col md={5} className="mb-2">
                          <Form.Label
                            style={{
                              margin: "7px 5px 0 0",
                            }}
                          >
                            From
                          </Form.Label>
                          <TextField
                            type="date"
                            size="small"
                            style={{ width: "200px" }}
                            onChange={(event) => {
                              if (
                                new Date() >= new Date(event.target.value) &&
                                new Date(purcahseDate.end) >=
                                  new Date(event.target.value)
                              ) {
                                purcahseDate.start = new Date(
                                  event.target.value
                                );
                              }
                            }}
                          />
                        </Col>
                        <Col md={5} className="mb-2 ">
                          <Form.Label
                            style={{
                              margin: "7px 27px 0 0",
                            }}
                          >
                            To
                          </Form.Label>
                          <TextField
                            size="small"
                            type="date"
                            style={{ width: "200px" }}
                            onChange={(event) => {
                              if (new Date() >= new Date(event.target.value)) {
                                purcahseDate.end = new Date(event.target.value);
                              }
                            }}
                          />
                        </Col>
                        <Col md={2}>
                          <center>
                            <Button
                              onClick={(e) => {
                                let data = [];
                                allPurchaseRef?.map((qua) => {
                                  const date = new Date(qua.date);
                                  if (
                                    (date.getUTCFullYear() >
                                      purcahseDate.start.getUTCFullYear() &&
                                      date.getUTCFullYear() <
                                        purcahseDate.end.getUTCFullYear()) ||
                                    (date.getUTCFullYear() >=
                                      purcahseDate.start.getUTCFullYear() &&
                                      date.getUTCMonth() >
                                        purcahseDate.start.getUTCMonth() &&
                                      date.getUTCFullYear() <=
                                        purcahseDate.end.getUTCFullYear() &&
                                      date.getUTCMonth() <
                                        purcahseDate.end.getUTCMonth()) ||
                                    (date.getUTCFullYear() >=
                                      purcahseDate.start.getUTCFullYear() &&
                                      date.getUTCMonth() >=
                                        purcahseDate.start.getUTCMonth() &&
                                      date.getUTCDate() >=
                                        purcahseDate.start.getUTCDate() &&
                                      date.getUTCFullYear() <=
                                        purcahseDate.end.getUTCFullYear() &&
                                      date.getUTCMonth() <=
                                        purcahseDate.end.getUTCMonth() &&
                                      date.getUTCDate() <=
                                        purcahseDate.end.getUTCDate())
                                  ) {
                                    data.push(qua);
                                  }
                                });
                                setPurchasesRef(data);
                              }}
                              variant="outline-info"
                            >
                              Search
                            </Button>
                          </center>
                        </Col>
                      </Form.Group>
                    </Form>
                    <hr />
                    <Box
                      sx={{
                        height: 300,
                        width: "100%",
                        "& .super-app-theme--header": {
                          backgroundColor: "#1de5ec",
                          color: "white",
                        },
                      }}
                    >
                      <DataGrid
                        rows={rows}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        columns={columns}
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        initialState={{
                          ...purchasesRef.initialState,
                          pagination: {
                            paginationModel: { pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10, 15]}
                        getRowId={(row) => row.id}
                        onCellClick={(row) => handleView(row.row)}
                      />
                    </Box>
                  </div>
                )}
                {/* {open && (
                  <div>
                    <hr />
                    <div>
                      <div>
                        <div className=" ml-1 mr-1">
                          <div className="row">
                            {newPurchases.map((rawOrder) => (
                              <div className="col-md-6 col-lg-4 mb-2 ">
                                <div
                                  className="row p-3"
                                  style={{
                                    width: "290px",
                                    height: "300px",
                                    borderRadius: "10px",
                                    background: "rgb(235, 235, 235)",
                                  }}
                                >
                                  <div className="mt-3 col-6">
                                    <img
                                      src={`https://garment.server.highendtradingplc.com/${
                                        rawMaterials.find(
                                          (p) => p.Id === rawOrder.rawMaterialId
                                        ).photo
                                      }`}
                                      style={{
                                        width: "120px",
                                        height: "120px",
                                      }}
                                      alt="Photo"
                                    />
                                  </div>

                                  <div
                                    style={{ float: "none" }}
                                    className="mt-3"
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        marginTop: "-20px",
                                      }}
                                      className=""
                                    >
                                      <b className="">Name:</b>
                                      <span className="">
                                        {
                                          rawMaterials.find(
                                            (p) =>
                                              p.Id === rawOrder.rawMaterialId
                                          )?.name
                                        }
                                      </span>
                                    </div>
                                    <br />
                                    <div
                                      style={{
                                        float: "left",
                                        marginTop: "-20px",
                                      }}
                                      className=""
                                    >
                                      <b className="">Type:</b>{" "}
                                      <span className="">
                                        {
                                          types.find(
                                            (t) =>
                                              t.Id ===
                                              rawMaterials.find(
                                                (p) =>
                                                  p.Id ===
                                                  rawOrder.rawMaterialId
                                              )?.typeId
                                          )?.typeName
                                        }
                                      </span>
                                    </div>
                                    <br />
                                    <div
                                      style={{
                                        float: "left",
                                        marginTop: "-20px",
                                      }}
                                      className=""
                                    >
                                      <b className="">Color:</b>{" "}
                                      <span className="">
                                        {
                                          colors.find(
                                            (t) =>
                                              t.Id ===
                                              rawMaterials.find(
                                                (p) =>
                                                  p.Id ===
                                                  rawOrder.rawMaterialId
                                              )?.colorId
                                          )?.colorName
                                        }
                                      </span>
                                    </div>
                                    <br />
                                    <div
                                      style={{
                                        float: "left",
                                        marginTop: "-20px",
                                      }}
                                      className=""
                                    >
                                      <b className="">Unit:</b>{" "}
                                      <span className="">
                                        {
                                          units.find(
                                            (t) =>
                                              t.Id ===
                                              rawMaterials.find(
                                                (p) =>
                                                  p.Id ===
                                                  rawOrder.rawMaterialId
                                              )?.unitId
                                          )?.unitName
                                        }
                                      </span>
                                    </div>
                                    <br />
                                    <div
                                      style={{
                                        float: "left",
                                        marginTop: "-20px",
                                      }}
                                      className=""
                                    >
                                      <b className="">Quantity:</b>{" "}
                                      <span className="">
                                        {parseFloat(rawOrder.quantity)}
                                      </span>
                                    </div>
                                    <br />
                                    <div
                                      style={{
                                        float: "left",
                                        marginTop: "-20px",
                                      }}
                                      className=""
                                    >
                                      <b className="">Price:</b>{" "}
                                      <span className="">
                                        {parseFloat(rawOrder.price)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </center>
        <center>
          <Modal
            show={open}
            onHide={(e) => {
              setOpen(false);
            }}
            size="lg"
          >
            <Modal.Header closeButton>
              <span>Purchase List</span>
            </Modal.Header>
            <Modal.Body>
              <DataGrid
                rows={purchaseRows}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                columns={purchaseColumns}
                components={{
                  Toolbar: GridToolbar,
                }}
                initialState={{
                  ...newPurchases.initialState,
                  pagination: {
                    paginationModel: { pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10, 15]}
                getRowId={(row) => row.Id}
              />
            </Modal.Body>
          </Modal>
        </center>
      </main>
    </div>
  );
};
export default RawPurchaseReport;
