/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import { Box, TextField } from "@mui/material";
import { faBackwardStep } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const RawOrderReport = () => {
  const [rawOrders, setRawOrders] = useState([]);
  const [allRawOrder, setAllRawOrder] = useState([]);
  const [productions, setProductions] = useState([]);
  const [productionOrders, setProductionOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [types, setTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [colors, setColors] = useState([]);
  const [rawMaterials, setRawMaterial] = useState([]);
  const [rawOrderRefs, setRawOrderRef] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [productionOrderRefs, setProductionOrederRefs] = useState([]);
  const [open, setOpen] = useState(false);
  const [rawOrderDate, setRawOrderDate] = useState({
    start: new Date(new Date().setUTCDate(new Date().getUTCDate() - 1)),
    end: new Date(new Date().setUTCDate(new Date().getUTCDate() + 1)),
  });
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    try {
      const [
        production,
        productionOrder,
        product,
        type,
        unit,
        rawMaterial,
        rawOrder,
        rawOrderRef,
        color,
        customer,
        productionOrderRef,
      ] = await Promise.all([
        axios.get("https://garment.server.highendtradingplc.com/production"),
        axios.get(
          "https://garment.server.highendtradingplc.com/productionOrder"
        ),
        axios.get("https://garment.server.highendtradingplc.com/products"),
        axios.get("https://garment.server.highendtradingplc.com/type"),
        axios.get("https://garment.server.highendtradingplc.com/unit"),
        axios.get("https://garment.server.highendtradingplc.com/rawMaterial"),
        axios.get("https://garment.server.highendtradingplc.com/rawOrder"),
        axios.get("https://garment.server.highendtradingplc.com/rawOrderRef"),
        axios.get("https://garment.server.highendtradingplc.com/color"),
        axios.get("https://garment.server.highendtradingplc.com/customer"),
        axios.get(
          "https://garment.server.highendtradingplc.com/productionOrderRef"
        ),
      ]);
      setAllRawOrder(rawOrder.data);
      setProductions(production.data);
      setProductionOrders(productionOrder.data);
      setProducts(product.data);
      setTypes(type.data);
      setUnits(unit.data);
      setRawMaterial(rawMaterial.data);
      setColors(color.data);
      setCustomers(customer.data);
      setProductionOrederRefs(productionOrderRef.data);
      let data = [];
      rawOrderRef.data?.map((qua) => {
        const date = new Date(qua.reqdate);
        if (
          (date.getUTCFullYear() > rawOrderDate.start.getUTCFullYear() &&
            date.getUTCFullYear() < rawOrderDate.end.getUTCFullYear()) ||
          (date.getUTCFullYear() >= rawOrderDate.start.getUTCFullYear() &&
            date.getUTCMonth() > rawOrderDate.start.getUTCMonth() &&
            date.getUTCFullYear() <= rawOrderDate.end.getUTCFullYear() &&
            date.getUTCMonth() < rawOrderDate.end.getUTCMonth()) ||
          (date.getUTCFullYear() >= rawOrderDate.start.getUTCFullYear() &&
            date.getUTCMonth() >= rawOrderDate.start.getUTCMonth() &&
            date.getUTCDate() >= rawOrderDate.start.getUTCDate() &&
            date.getUTCFullYear() <= rawOrderDate.end.getUTCFullYear() &&
            date.getUTCMonth() <= rawOrderDate.end.getUTCMonth() &&
            date.getUTCDate() <= rawOrderDate.end.getUTCDate())
        ) {
          data.push(qua);
        }
        setRawOrderRef(data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const handleView = (row) => {
    const order = allRawOrder.filter((p) => p.rawOrderRefId === row.row.id);
    setRawOrders(order);
    setOpen(true);
  };
  const rawColumns = [
    {
      field: "Id",
      headerAlign: "center",
      align: "center",
      headerName: "Order Ref ID",
      minWidth: 80,
    },
    {
      field: "id",
      headerAlign: "center",
      align: "center",
      headerName: "Id",
      minWidth: 100,
    },
    {
      field: "name",
      headerAlign: "center",
      align: "center",
      headerName: "Raw Material Name",
      minWidth: 150,
    },
    {
      field: "type",
      headerAlign: "center",
      align: "center",
      headerName: "Type",
      minWidth: 100,
    },
    {
      field: "color",
      headerAlign: "center",
      align: "center",
      headerName: "Color",
      minWidth: 100,
    },
    {
      field: "unit",
      headerAlign: "center",
      align: "center",
      headerName: "Unit",
      minWidth: 100,
    },
  ];
  const rawRows = rawOrders.map((qlt) => {
    console.log(qlt);
    // const rawOrderRef = rawOrderRefs.find((r) => r.Id === qlt.rawOrderRefId);
    // console.log(rawOrderRefs, qlt);
    // // const productionOrder = productionOrders.find(
    // //   (p) => p.Id === rawOrderRef.productionOrderId
    // // );
    const rawMaterial = rawMaterials.find((r) => r.Id === qlt.rawMaterialId);
    // const product = products.find((p) => p.Id === productionOrder.productId);
    const type = types.find((t) => t.Id === rawMaterial.typeId);
    const unit = units.find((u) => u.Id === rawMaterial.unitId);
    const color = colors.find((c) => c.Id === rawMaterial.colorId);
    return {
      Id: qlt.rawOrderRefId,
      id: qlt.Id,
      name: rawMaterial.name,
      type: type.typeName,
      color: color.colorName,
      unit: unit.unitName,
    };
  });
  const columns = [
    {
      field: "Id",
      headerAlign: "center",
      align: "center",
      headerName: "#",
      minWidth: 80,
    },
    {
      field: "id",
      headerAlign: "center",
      align: "center",
      headerName: "Order Ref ID",
      minWidth: 120,
    },
    {
      field: "name",
      headerAlign: "center",
      align: "center",
      headerName: "Full Name",
      minWidth: 150,
    },
    {
      field: "date",
      headerAlign: "center",
      align: "center",
      headerName: "Receive Date",
      minWidth: 120,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];
  const rows = rawOrderRefs.map((qlt, index) => {
    const productionOrderRef = productionOrderRefs.find(
      (p) => p.Id === qlt.productionOrderRefId
    );
    const customer = customers.find(
      (c) => c.Id === productionOrderRef.customerId
    );
    // const rawOrderRef = rawOrderRefs.find((r) => r.Id === qlt.rawOrderRefId);
    // console.log(rawOrderRefs, qlt);
    // // const productionOrder = productionOrders.find(
    // //   (p) => p.Id === rawOrderRef.productionOrderId
    // // );
    // const rawMaterial = rawMaterials.find((r) => r.Id === qlt.rawMaterialId);
    // // const product = products.find((p) => p.Id === productionOrder.productId);
    // const type = types.find((t) => t.Id === rawMaterial.typeId);
    // const unit = units.find((u) => u.Id === rawMaterial.unitId);
    // const color = colors.find((c) => c.Id === rawMaterial.colorId);
    return {
      Id: index + 1,
      id: qlt.Id,
      name: customer.fullName,
      date: qlt?.reqdate,
    };
  });

  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div
              className="tab-container "
              style={{
                marginTop: "-20px",
                marginBottom: "-5px",
              }}
            >
              <Link className="p-2" to={`/production_order_report`}>
                Productions Order
              </Link>
              <Link className="p-2" to={`/sales_report`}>
                Sales
              </Link>
              <Link className="p-2" to={`/raw_purchase_report`}>
                Raw Purchase
              </Link>

              <Link
                className="p-2"
                onClick={(e) => window.location.reload()}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px 5px 0 0",
                  border: "solid 0.05em rgb(181, 181, 181)",
                }}
              >
                Raw Order
              </Link>
              <Link className="p-2" to={`/quality_checker_report`}>
                Quality Check
              </Link>
            </div>
            <div className="card card-info card-outline">
              {open && (
                <div className="card-header">
                  <Button
                    onClick={(e) => {
                      setOpen(false);
                    }}
                    variant="outline-success"
                    className="m-1"
                    style={{ float: "right" }}
                  >
                    <div
                      className="row"
                      style={{
                        marginBottom: "-10px",
                        marginLeft: "-10px",
                      }}
                    >
                      <div className="col">
                        <FontAwesomeIcon
                          style={{ float: "right", marginRight: "-1px" }}
                          icon={faBackwardStep}
                        />
                      </div>
                      <div className="col-7">
                        <h6
                          style={{
                            float: "left",
                            marginLeft: "-10px",
                          }}
                        >
                          Back
                        </h6>
                      </div>
                    </div>
                  </Button>
                </div>
              )}
              <div className="card-body">
                <div
                  className="nav-bra pl-3 ml-1 p-2 "
                  style={{
                    backgroundColor: "rgb(235, 235, 235)",
                    height: "40px",
                  }}
                >
                  <span style={{ float: "left" }}>Home / Raw Order Report</span>
                </div>
                <br />
                <div style={{ border: "solid 1px #ffb71b" }}></div>
                <br />
                {!open && (
                  <div>
                    <Form>
                      <Form.Group as={Row}>
                        <Col md={5} className="mb-2">
                          <Form.Label
                            style={{
                              margin: "7px 5px 0 0",
                            }}
                          >
                            From
                          </Form.Label>
                          <TextField
                            type="date"
                            size="small"
                            style={{ width: "200px" }}
                            onChange={(event) => {
                              if (
                                new Date() >= new Date(event.target.value) &&
                                new Date(rawOrderDate.end) >=
                                  new Date(event.target.value)
                              ) {
                                rawOrderDate.start = new Date(
                                  event.target.value
                                );
                              }
                            }}
                          />
                        </Col>
                        <Col md={5} className="mb-2 ">
                          <Form.Label
                            style={{
                              margin: "7px 27px 0 0",
                            }}
                          >
                            To
                          </Form.Label>
                          <TextField
                            size="small"
                            type="date"
                            style={{ width: "200px" }}
                            onChange={(event) => {
                              if (new Date() >= new Date(event.target.value)) {
                                rawOrderDate.end = new Date(event.target.value);
                              }
                            }}
                          />
                        </Col>
                        <Col md={2}>
                          <center>
                            <Button
                              onClick={(e) => {
                                let data = [];
                                rawOrderRefs?.map((qua) => {
                                  const date = new Date(qua.reqdate);
                                  if (
                                    (date.getUTCFullYear() >
                                      rawOrderDate.start.getUTCFullYear() &&
                                      date.getUTCFullYear() <
                                        rawOrderDate.end.getUTCFullYear()) ||
                                    (date.getUTCFullYear() >=
                                      rawOrderDate.start.getUTCFullYear() &&
                                      date.getUTCMonth() >
                                        rawOrderDate.start.getUTCMonth() &&
                                      date.getUTCFullYear() <=
                                        rawOrderDate.end.getUTCFullYear() &&
                                      date.getUTCMonth() <
                                        rawOrderDate.end.getUTCMonth()) ||
                                    (date.getUTCFullYear() >=
                                      rawOrderDate.start.getUTCFullYear() &&
                                      date.getUTCMonth() >=
                                        rawOrderDate.start.getUTCMonth() &&
                                      date.getUTCDate() >=
                                        rawOrderDate.start.getUTCDate() &&
                                      date.getUTCFullYear() <=
                                        rawOrderDate.end.getUTCFullYear() &&
                                      date.getUTCMonth() <=
                                        rawOrderDate.end.getUTCMonth() &&
                                      date.getUTCDate() <=
                                        rawOrderDate.end.getUTCDate())
                                  ) {
                                    allRawOrder.map((a) => {
                                      if (a.rawOrderRefId === qua.Id) {
                                        data.push(a);
                                      }
                                    });
                                  }
                                });
                                setRawOrders(data);
                              }}
                              variant="outline-info"
                            >
                              Search
                            </Button>
                          </center>
                        </Col>
                      </Form.Group>
                    </Form>
                    <hr />
                    <Box
                      sx={{
                        height: 300,
                        width: "100%",
                        "& .super-app-theme--header": {
                          backgroundColor: "#1de5ec",
                          color: "white",
                        },
                      }}
                    >
                      <DataGrid
                        rows={rows}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        columns={columns}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        initialState={{
                          ...productions.initialState,
                          pagination: {
                            paginationModel: { pageSize: 5 },
                          },
                        }}
                        onCellClick={(row) => handleView(row)}
                        pageSizeOptions={[5, 10, 15]}
                        getRowId={(row) => row.id}
                      />
                    </Box>
                  </div>
                )}
                {/* {open && (
                  <div>
                    <hr />
                    <div>
                      <div>
                        <div className=" ml-1 mr-1">
                          <div className="row">
                            {rawOrders.map((rawOrder) => (
                              <div className="col-md-6 col-lg-4 mb-2 ">
                                <div
                                  className="row p-3"
                                  style={{
                                    width: "290px",
                                    height: "300px",
                                    borderRadius: "10px",
                                    background: "rgb(235, 235, 235)",
                                  }}
                                >
                                  <div className="mt-3 col-6">
                                    <img
                                      src={`https://garment.server.highendtradingplc.com/${
                                        rawMaterials.find(
                                          (p) => p.Id === rawOrder.rawMaterialId
                                        ).photo
                                      }`}
                                      style={{
                                        width: "120px",
                                        height: "120px",
                                      }}
                                      alt="Photo"
                                    />
                                  </div>

                                  <div
                                    style={{ float: "none" }}
                                    className="mt-3"
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        marginTop: "-20px",
                                      }}
                                      className=""
                                    >
                                      <b className="">Name:</b>
                                      <span className="">
                                        {
                                          rawMaterials.find(
                                            (p) =>
                                              p.Id === rawOrder.rawMaterialId
                                          )?.name
                                        }
                                      </span>
                                    </div>
                                    <br />
                                    <div
                                      style={{
                                        float: "left",
                                        marginTop: "-20px",
                                      }}
                                      className=""
                                    >
                                      <b className="">Type:</b>{" "}
                                      <span className="">
                                        {
                                          types.find(
                                            (t) =>
                                              t.Id ===
                                              rawMaterials.find(
                                                (p) =>
                                                  p.Id ===
                                                  rawOrder.rawMaterialId
                                              )?.typeId
                                          )?.typeName
                                        }
                                      </span>
                                    </div>
                                    <br />
                                    <div
                                      style={{
                                        float: "left",
                                        marginTop: "-20px",
                                      }}
                                      className=""
                                    >
                                      <b className="">Color:</b>{" "}
                                      <span className="">
                                        {
                                          colors.find(
                                            (t) =>
                                              t.Id ===
                                              rawMaterials.find(
                                                (p) =>
                                                  p.Id ===
                                                  rawOrder.rawMaterialId
                                              )?.colorId
                                          )?.colorName
                                        }
                                      </span>
                                    </div>
                                    <br />
                                    <div
                                      style={{
                                        float: "left",
                                        marginTop: "-20px",
                                      }}
                                      className=""
                                    >
                                      <b className="">Unit:</b>{" "}
                                      <span className="">
                                        {
                                          units.find(
                                            (t) =>
                                              t.Id ===
                                              rawMaterials.find(
                                                (p) =>
                                                  p.Id ===
                                                  rawOrder.rawMaterialId
                                              )?.unitId
                                          )?.unitName
                                        }
                                      </span>
                                    </div>
                                    <br />
                                    <div
                                      style={{
                                        float: "left",
                                        marginTop: "-20px",
                                      }}
                                      className=""
                                    >
                                      <b className="">Quantity:</b>{" "}
                                      <span className="">
                                        {parseFloat(rawOrder.quantity)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </center>

        <Modal
          show={open}
          onHide={(e) => {
            setOpen(false);
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <span>Order List</span>
          </Modal.Header>
          <Modal.Body>
            <DataGrid
              rows={rawRows}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              columns={rawColumns}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
            />
          </Modal.Body>
        </Modal>
      </main>
    </div>
  );
};
export default RawOrderReport;
