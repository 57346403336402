/** @format */
import react, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUsers,
  faCog,
  faSignOutAlt,
  faUserPlus,
  faBarcode,
  faBasketShopping,
  faFile,
  faCheckDouble,
  faLocationDot,
  faBarsStaggered,
  faListUl,
  faStore,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import "./App.css";

const Header = () => {
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const [allData, setAllData] = useState({
    superAdmin: false,
    admin: false,
    qualityChecker: false,
    storeMan: false,
    sales: false,
    productionTeam: false,
  });
  const navigate = useNavigate();

  const fetchAllData = () => {
    let newapi = window.location.pathname;
    if (roleData.roleId === 1) {
      setAllData((prev) => ({ ...prev, superAdmin: true }));
    } else if (roleData.roleId === 2) {
      setAllData((prev) => ({ ...prev, admin: true }));
    } else if (roleData.roleId === 3) {
      setAllData((prev) => ({ ...prev, sales: true }));
    } else if (roleData.roleId === 4) {
      setAllData((prev) => ({ ...prev, qualityChecker: true }));
    } else if (roleData.roleId === 5) {
      setAllData((prev) => ({ ...prev, storeMan: true }));
    } else if (roleData.roleId === 6) {
      setAllData((prev) => ({ ...prev, productionTeam: true }));
    } else {
      navigate(`/`);
    }
    window.sessionStorage.setItem("api", JSON.stringify({ api: newapi }));
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  return (
    <header className="bg-body-tertiary fixed-top">
      <div className="App-header-top row ">
        <div className="row" style={{ width: "100vw" }}>
          <div className="col-5">
            <div className="row">
              <div className="col text-right" style={{ overflow: "hidden" }}>
                <span style={{ fontSize: 18 }}>Garment</span>
              </div>
              <div className="col-2 text-left">
                <Link to={`/home`} style={{ color: "white", fontSize: 14 }}>
                  Home
                </Link>
              </div>
            </div>
          </div>

          <div className="col-7">
            <div className="row">
              <Link
                to="/user_mgt"
                className="col text-right"
                style={{
                  color: "white",
                  fontSize: 14,
                }}
              >
                <FontAwesomeIcon icon={faCog} />
                Setting
              </Link>
              {/* <Link
                className="col text-center"
                style={{ color: "white", fontSize: 14, overflow: "hidden" }}
              >
                Hello<span style={{ color: "#30bde4" }}>_</span>superadmin!
              </Link> */}
              <Link
                onClick={(e) => {
                  window.sessionStorage.clear();
                  window.location.reload();
                }}
                to={`/`}
                className="col text-left"
                style={{ color: "white", fontSize: 14 }}
              >
                <FontAwesomeIcon icon={faSignOutAlt} />
                Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="App-header-bottom ">
        <nav className="navbar-menu ml-5">
          <div className="row">
            <Link
              className="col-sm-3 col-3 col-md-1 col-lg-1 col-xl-1"
              to={`/customers`}
              onClick={(e) => {
                if (window.location.pathname === "/customers")
                  window.location.reload();
              }}
              style={
                window.location.pathname === "/customers"
                  ? {
                      backgroundColor: "rgb(235,235,235)",
                      minWidth: "105px",
                    }
                  : { minWidth: "105px" }
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/customers"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon icon={faUserPlus} size="2x" />
                  </div>
                  <div>Customers</div>
                </div>
              </center>
            </Link>
            <Link
              className="col-sm-2 col-3 col-lg-1 col-xl-1 col-md-1"
              to={`/vendor`}
              onClick={(e) => {
                if (window.location.pathname === "/vendor")
                  window.location.reload();
              }}
              style={
                window.location.pathname === "/vendor"
                  ? { backgroundColor: "rgb(235,235,235)", minWidth: "105px" }
                  : { minWidth: "105px" }
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/vendor"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faUser} />
                  </div>
                  <div>Vendor</div>
                </div>
              </center>
            </Link>
            <Link
              className="col-sm-2 col-3 col-lg-1 col-xl-1 col-md-1"
              to={`/quality_checker`}
              onClick={(e) => {
                if (window.location.pathname === "/quality_checker")
                  window.location.reload();
              }}
              style={
                window.location.pathname === "/quality_checker"
                  ? { backgroundColor: "rgb(235,235,235)", minWidth: "105px" }
                  : { minWidth: "105px" }
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/quality_checker"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faCheckDouble} />
                  </div>
                  <div>ChecK Quality</div>
                </div>
              </center>
            </Link>
            <Link
              className="col-sm-2 col-3 col-lg-1 col-xl-1 col-md-1"
              onClick={(e) => {
                if (
                  window.location.pathname === "/branch" ||
                  window.location.pathname === "/raw_material/types/4"
                )
                  window.location.reload();
              }}
              to={`/branch`}
              style={
                window.location.pathname === "/branch" ||
                window.location.pathname === "/raw_material/types/4"
                  ? { backgroundColor: "rgb(235,235,235)", minWidth: "105px" }
                  : { minWidth: "105px" }
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/branch" ||
                    window.location.pathname === "/raw_material/types/4"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faLocationDot} />
                  </div>
                  <div>Branch</div>
                </div>
              </center>
            </Link>
            <Link
              className="col-sm-2 col-3 col-lg-1 col-xl-1 col-md-1"
              onClick={(e) => {
                if (
                  window.location.pathname === "/raw_material" ||
                  window.location.pathname === "/raw_material/types/5" ||
                  window.location.pathname === "/raw_material/types/3" ||
                  window.location.pathname === "/raw_material/types/2" ||
                  window.location.pathname === "/raw_material/types/1" ||
                  window.location.pathname === "/raw_purchase" ||
                  window.location.pathname === "/raw_purchase_list" ||
                  window.location.pathname === "/products"
                )
                  window.location.reload();
              }}
              to={`/raw_material`}
              style={
                window.location.pathname === "/raw_material" ||
                window.location.pathname === "/raw_material/types/5" ||
                window.location.pathname === "/raw_material/types/3" ||
                window.location.pathname === "/raw_material/types/2" ||
                window.location.pathname === "/raw_material/types/1" ||
                window.location.pathname === "/raw_purchase" ||
                window.location.pathname === "/raw_purchase_list" ||
                window.location.pathname === "/products"
                  ? { backgroundColor: "rgb(235,235,235)", minWidth: "105px" }
                  : { minWidth: "105px" }
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/raw_material" ||
                    window.location.pathname === "/raw_material/types/5" ||
                    window.location.pathname === "/raw_material/types/3" ||
                    window.location.pathname === "/raw_material/types/2" ||
                    window.location.pathname === "/raw_material/types/1" ||
                    window.location.pathname === "/raw_purchase" ||
                    window.location.pathname === "/raw_purchase_list" ||
                    window.location.pathname === "/products"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faBarcode} />
                  </div>
                  <div>Material</div>
                </div>
              </center>
            </Link>
            <Link
              className="col-sm-2 col-3 col-lg-1 col-xl-1 col-md-1"
              onClick={(e) => {
                if (
                  window.location.pathname === "/sales" ||
                  window.location.pathname === "/sales_list"
                )
                  window.location.reload();
              }}
              to={
                allData.admin || allData.sales
                  ? `/sales`
                  : window.location.pathname
              }
              style={
                window.location.pathname === "/sales" ||
                window.location.pathname === "/sales_list"
                  ? { backgroundColor: "rgb(235,235,235)", minWidth: "105px" }
                  : { minWidth: "105px" }
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/sales" ||
                    window.location.pathname === "/sales_list"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faBasketShopping} />
                  </div>
                  <div>Sales</div>
                </div>
              </center>
            </Link>
            <Link
              className="col-sm-2 col-3 col-lg-1 col-xl-1 col-md-1"
              onClick={(e) => {
                if (window.location.pathname === "/production")
                  window.location.reload();
              }}
              to={
                allData.admin || allData.productionTeam
                  ? `/production`
                  : window.location.pathname
              }
              style={
                window.location.pathname === "/production"
                  ? { backgroundColor: "rgb(235,235,235)", minWidth: "105px" }
                  : { minWidth: "105px" }
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/production"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faListUl} />
                  </div>
                  <div>Production</div>
                </div>
              </center>
            </Link>
            <Link
              className="col-sm-2 col-3 col-lg-1 col-xl-1 col-md-1"
              onClick={(e) => {
                if (window.location.pathname === "/status_report")
                  window.location.reload();
              }}
              to={allData.admin ? `/status_report` : window.location.pathname}
              style={
                window.location.pathname === "/status_report"
                  ? { backgroundColor: "rgb(235,235,235)", minWidth: "105px" }
                  : { minWidth: "105px" }
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/status_report"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faListCheck} />
                  </div>
                  <div>Order Status</div>
                </div>
              </center>
            </Link>
            <Link
              className="col-sm-2 col-3 col-lg-1 col-xl-1 col-md-1"
              onClick={(e) => {
                if (window.location.pathname === "/raw_order/2")
                  window.location.reload();
              }}
              to={
                allData.admin || allData.storeMan
                  ? `/raw_order/2`
                  : window.location.pathname
              }
              style={
                window.location.pathname === "/raw_order/2"
                  ? { backgroundColor: "rgb(235,235,235)", minWidth: "105px" }
                  : { minWidth: "105px" }
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/raw_order/2"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faStore} />
                  </div>
                  <div>Store</div>
                </div>
              </center>
            </Link>
            <Link
              className="col-sm-2 col-3 col-lg-1 col-xl-1 col-md-1"
              onClick={(e) => {
                if (
                  window.location.pathname === "/raw_order/1" ||
                  window.location.pathname === "/production_order"
                )
                  window.location.reload();
              }}
              to={
                allData.storeMan
                  ? `/raw_order/1`
                  : allData.sales || allData.admin
                  ? `/production_order`
                  : window.location.pathname
              }
              style={
                window.location.pathname === "/raw_order/1" ||
                window.location.pathname === "/production_order"
                  ? { backgroundColor: "rgb(235,235,235)", minWidth: "105px" }
                  : { minWidth: "105px" }
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/raw_order/1" ||
                    window.location.pathname === "/production_order"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faBarsStaggered} />
                  </div>
                  <div>Order</div>
                </div>
              </center>
            </Link>
            <Link
              className="col-sm-2 col-3 col-lg-1 col-xl-1 col-md-1"
              onClick={(e) => {
                if (
                  window.location.pathname === "/user_mgt" ||
                  window.location.pathname === "/role_mgt" ||
                  window.location.pathname === "/account_mgt"
                )
                  window.location.reload();
              }}
              to={`/user_mgt`}
              style={
                window.location.pathname === "/user_mgt" ||
                window.location.pathname === "/role_mgt" ||
                window.location.pathname === "/account_mgt"
                  ? { backgroundColor: "rgb(235,235,235)", minWidth: "105px" }
                  : { minWidth: "105px" }
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/user_mgt" ||
                    window.location.pathname === "/role_mgt" ||
                    window.location.pathname === "/account_mgt"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faUsers} />
                  </div>
                  <div>User Mgt</div>
                </div>
              </center>
            </Link>
            <Link
              className="col-sm-2 col-3 col-lg-1 col-xl-1 col-md-1"
              onClick={(e) => {
                if (
                  window.location.pathname === "/raw_purchase_report" ||
                  window.location.pathname === "/sales_report" ||
                  window.location.pathname === "/raw_order_report" ||
                  window.location.pathname === "/production_order_report" ||
                  window.location.pathname === "/quality_checker_report"
                )
                  window.location.reload();
              }}
              to={
                allData.admin || allData.sales
                  ? `/production_order_report`
                  : allData.qualityChecker
                  ? `/quality_checker_report`
                  : allData.storeMan
                  ? `/raw_purchase_report`
                  : allData.productionTeam
                  ? `/raw_order_report`
                  : window.location.pathname
              }
              style={
                window.location.pathname === "/raw_purchase_report" ||
                window.location.pathname === "/sales_report" ||
                window.location.pathname === "/raw_order_report" ||
                window.location.pathname === "/production_order_report" ||
                window.location.pathname === "/quality_checker_report"
                  ? { backgroundColor: "rgb(235,235,235)", minWidth: "105px" }
                  : { minWidth: "105px" }
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/raw_purchase_report" ||
                    window.location.pathname === "/sales_report" ||
                    window.location.pathname === "/raw_order_report" ||
                    window.location.pathname === "/production_order_report" ||
                    window.location.pathname === "/quality_checker_report"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faFile} />
                  </div>
                  <div>Report</div>
                </div>
              </center>
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
