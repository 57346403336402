/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import axios from "axios";
import Header from "../Header";
import {
  faPenToSquare,
  faTrashCan,
  faCalendar,
  faForwardStep,
  faBackwardStep,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const validationSchema = Yup.object().shape({
  statusId: Yup.string().required("Status is required"),
  userId: Yup.string().required("User is required"),
  receiveDate: Yup.date().required("Date is required"),
});
const QualityCheckerPage = () => {
  const [open, setOpen] = useState(false);
  const [productions, setProductions] = useState([]);
  const [productionOrderRefs, setProductionOrderRefs] = useState([]);
  const [products, setProducts] = useState([]);
  const [types, setTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [productionOrders, setProductionOrders] = useState([]);
  const [productData, setProductData] = useState([]);
  const [qualityCheck, setQualityCheck] = useState([]);
  const [qualityCheckers, setQualityCheckers] = useState([]);
  const [statusOrders, setStatusOrders] = useState([]);
  const [openSomeApprove, setOpenSomeApprove] = useState(false);
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    try {
      const [
        production,
        productionOrderRef,
        product,
        type,
        qualityChecker,
        customer,
        productionOrder,
        statusOrder,
      ] = await Promise.all([
        axios.get("https://garment.server.highendtradingplc.com/production"),
        axios.get(
          "https://garment.server.highendtradingplc.com/productionOrderRef"
        ),
        axios.get("https://garment.server.highendtradingplc.com/products"),
        axios.get("https://garment.server.highendtradingplc.com/type"),
        axios.get(
          "https://garment.server.highendtradingplc.com/qualityChecker"
        ),
        axios.get("https://garment.server.highendtradingplc.com/customer"),
        axios.get(
          "https://garment.server.highendtradingplc.com/productionOrder"
        ),
        axios.get("https://garment.server.highendtradingplc.com/statusOrder"),
      ]);
      console.log(product.data);
      let data = [];
      production.data.map((p) => {
        const h = qualityChecker.data.find((q) => q.productionId === p.Id);
        console.log(h);
        if ((!!!h || h?.statusId === 3) && !!p?.endingDate) {
          data.push(p);
        }
      });
      setQualityCheckers(qualityChecker.data);
      setStatusOrders(statusOrder.data);
      setProductions(data);
      setProductionOrderRefs(productionOrderRef.data);
      setProducts(product.data);
      setTypes(type.data);
      setUsers(customer.data);
      setProductionOrders(productionOrder.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      productionId: "",
      receiveDate: "",
      userId: "",
      statusId: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      try {
        await axios.post(
          "https://garment.server.highendtradingplc.com/qualityChecker/add",
          values
        );
        setOpen(false);
      } catch (error) {
        console.log(error);
      } finally {
        values.productionId = "";
        values.receiveDate = "";
        values.userId = "";
        values.statusId = "";
        fetchAllData();
      }
    },
  });

  const handleUpdate = (data) => {
    // formik.values.productionId = id;
    const production = productions.find((p) => p.Id === data.row.productionId);
    const productionOrder = productionOrders.filter(
      (p) =>
        p.productionOrderRefId === production?.productionOrderRefId &&
        p.status === 1
    );
    setQualityCheck(production);
    setProductData(productionOrder);
    setOpen(true);
  };
  const handleOpen = () => {
    formik.values.productionId = "";
    formik.values.userId = "";
    formik.values.statusId = "";
    formik.values.receiveDate = "";
    if (openSomeApprove) {
      setOpenSomeApprove(false);
    } else {
      setOpen(false);
    }
  };
  const columns = [
    { field: "Id", headerName: "ID", width: 50 },
    { field: "fullName", headerName: "FullName", width: 150 },
    {
      field: "receiveDate",
      headerName: "Order Recieve Date",
      minWidth: 140,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
    {
      field: "deadlineDate",
      headerName: "Order Deadline Date",
      minWidth: 145,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
    {
      field: "start",
      headerName: "Start Date",
      minWidth: 110,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate() || "-"}/${
          new Date(params).getUTCMonth() + 1 || "-"
        }/${new Date(params).getUTCFullYear() || "-"}`,
    },
    {
      field: "end",
      headerName: "Raw Receive Date",
      minWidth: 140,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate() || "-"}/${
          new Date(params).getUTCMonth() + 1 || "-"
        }/${new Date(params).getUTCFullYear() || "-"}`,
    },
  ];
  const rows = productions?.map((production, index) => {
    const orders = productionOrderRefs.find(
      (order) => order.Id === production.productionOrderRefId
    );

    const customer = users.find((c) => c.Id == orders.customerId);

    return {
      Id: index + 1,
      id: orders?.Id,
      productionId: production?.Id,
      fullName: customer?.fullName,
      receiveDate: orders?.orderReceiveDate,
      deadlineDate: orders?.orderDeadlineDate,
      start: production?.startedDate || "",
      end: production?.materialReceiveDate || "",
    };
  });

  return (
    <div className="">
      <div>
        <Header />
      </div>
      <main className="" style={{ marginTop: "190px" }}>
        <center>
          <div className="" style={{ maxWidth: "1100px" }}>
            <div className="card card-info card-outline">
              <div className="card-header pl-2">
                {open && (
                  <Button
                    onClick={handleOpen}
                    variant="outline-success"
                    className="m-1"
                    style={{ float: "right" }}
                  >
                    <div
                      className="row"
                      style={{
                        marginBottom: "-10px",
                        marginLeft: "-10px",
                      }}
                    >
                      <div className="col">
                        <FontAwesomeIcon
                          style={{ float: "right", marginRight: "-1px" }}
                          icon={faBackwardStep}
                        />
                      </div>
                      <div className="col-7">
                        <h6
                          style={{
                            float: "left",
                            marginLeft: "-10px",
                          }}
                        >
                          Back
                        </h6>
                      </div>
                    </div>
                  </Button>
                )}
              </div>
              <div className="card-body">
                <div>
                  <div
                    className="nav-bra pl-3 ml-1 p-2"
                    style={{
                      backgroundColor: "rgb(235, 235, 235)",
                      height: "40px",
                    }}
                  >
                    <span style={{ float: "left" }}>
                      Home / Quality Checker {open && "/ Insert"}
                    </span>
                  </div>
                  <hr />
                </div>
                {!open && (
                  <div>
                    <DataGrid
                      rows={rows}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      columns={columns}
                      slots={{ toolbar: GridToolbar }}
                      onCellClick={(row) => handleUpdate(row)}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      initialState={{
                        ...productions.initialState,
                        pagination: {
                          paginationModel: { pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10, 15]}
                      getRowId={(row) => row.Id}
                    />
                  </div>
                )}
                {open && (
                  <div className=" ml-1 mr-1">
                    <div className="row">
                      {productData.map((rawOrder) => (
                        <div className="col-md-6 col-lg-4 mb-2 ">
                          <div
                            className="row p-3"
                            style={{
                              width: "290px",
                              borderRadius: "40px",
                              background: "rgb(235, 235, 235)",
                            }}
                          >
                            <div className="mt-3 col-6">
                              <img
                                src={`https://garment.server.highendtradingplc.com/${
                                  products.find(
                                    (p) => p.Id === rawOrder.productId
                                  ).photo
                                }`}
                                style={{
                                  width: "120px",
                                  height: "120px",
                                }}
                                alt="Photo"
                              />
                            </div>
                            <div
                              className=" mb-3  col-6"
                              style={{ marginTop: "-30px" }}
                            >
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId && p.typeId === 1
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>{`የሸሚዝ ቁመት`}:</b>{" "}
                                    <span>{rawOrder.scale4}</span>
                                  </div>
                                </>
                              )}
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId && p.typeId === 2
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>{`ከወገብ ቁመት`}:</b>{" "}
                                    <span>{rawOrder.scale4}</span>
                                  </div>
                                </>
                              )}
                              {!!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId &&
                                  (p.typeId === 2 || p.typeId === 1)
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>{`ቁመት`}:</b>{" "}
                                    <span>{rawOrder.scale4}</span>
                                  </div>
                                </>
                              )}
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId &&
                                  (p.typeId === 2 || p.typeId === 1)
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>{`ወገብ ዙሪያ`}:</b>{" "}
                                    <span>{rawOrder.scale4}</span>
                                  </div>
                                </>
                              )}
                              {!!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId &&
                                  (p.typeId === 2 || p.typeId === 1)
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>{`ወገብ`}:</b>{" "}
                                    <span>{rawOrder.scale4}</span>
                                  </div>
                                </>
                              )}
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId &&
                                  (p.typeId === 2 || p.typeId === 1)
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>{`ትክሻ`}:</b>{" "}
                                    <span>{rawOrder.scale4}</span>
                                  </div>
                                </>
                              )}
                              {!!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId &&
                                  (p.typeId === 2 || p.typeId === 1)
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>{`ታፋ`}:</b>{" "}
                                    <span>{rawOrder.scale4}</span>
                                  </div>
                                </>
                              )}

                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId &&
                                  (p.typeId === 2 || p.typeId === 1)
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>{`የእጅ ቁመት`}:</b>{" "}
                                    <span>{rawOrder.scale4}</span>
                                  </div>
                                </>
                              )}
                              {!!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId &&
                                  (p.typeId === 2 || p.typeId === 1)
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>{`ዳሌ`}:</b>{" "}
                                    <span>{rawOrder.scale4}</span>
                                  </div>
                                </>
                              )}
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId && p.typeId === 2
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    <b>{`ጡት ዙሪያ`}:</b>{" "}
                                    <span>{rawOrder.scale5}</span>
                                  </div>
                                </>
                              )}
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId && p.typeId === 1
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    <b>{`ደረት ዙሪያ`}:</b>{" "}
                                    <span>{rawOrder.scale5}</span>
                                  </div>
                                </>
                              )}
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId && p.typeId === 2
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    <b>{`ከትክሻ ወገብ`}:</b>{" "}
                                    <span>{rawOrder.scale6}</span>
                                  </div>
                                </>
                              )}
                              <br />
                              <div style={{ float: "left" }}>
                                {" "}
                                <b>Quantity:</b>{" "}
                                <span>{rawOrder.quantity}</span>
                              </div>
                            </div>
                            <div style={{ float: "none" }} className="">
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId && p.typeId === 3
                              ) && (
                                <>
                                  <br />
                                </>
                              )}
                              <div
                                style={{
                                  float: "left",
                                  marginTop: "-20px",
                                }}
                                className=""
                              >
                                <b className="">Name:</b>
                                <span className="">
                                  {
                                    products.find(
                                      (p) => p.Id === rawOrder.productId
                                    )?.name
                                  }
                                </span>
                              </div>
                              <br />
                              <div
                                style={{
                                  float: "left",
                                  marginTop: "-20px",
                                }}
                                className=""
                              >
                                <b className="">Type:</b>{" "}
                                <span className="">
                                  {
                                    types.find(
                                      (t) =>
                                        t.Id ===
                                        products.find(
                                          (p) => p.Id === rawOrder.productId
                                        )?.typeId
                                    )?.typeName
                                  }
                                </span>
                              </div>
                            </div>
                            {openSomeApprove && (
                              <div>
                                <center>
                                  <Button
                                    variant="outline-success"
                                    className="m-1"
                                    onClick={async (e) => {
                                      try {
                                        let status = statusOrders.find(
                                          (s) =>
                                            s.productionOrderRefId ===
                                            qualityCheck.productionOrderRefId
                                        );
                                        status.status = 5;
                                        const q = qualityCheckers.find(
                                          (p) =>
                                            p.productionId === qualityCheck.Id
                                        );
                                        const pro = productData.length;
                                        setProductData(
                                          productData.filter(
                                            (p) => p.Id !== rawOrder.Id
                                          )
                                        );
                                        if (!!!q && pro === 1) {
                                          await axios.post(
                                            "https://garment.server.highendtradingplc.com/qualityChecker/add",
                                            {
                                              productionId: qualityCheck.Id,
                                              receiveDate: new Date(),
                                              userId: roleData.Id,
                                              statusId: 1,
                                            }
                                          );
                                          fetchAllData();
                                          setOpen(false);
                                          setOpenSomeApprove(false);
                                        }
                                        if (pro === 1) {
                                          const check = productData.find(
                                            (p) => p.status !== 2
                                          );
                                          const prod = productionOrders.find(
                                            (p) =>
                                              p.productionOrderRefId ===
                                                check.productionOrderRefId &&
                                              check.Id !== p.Id &&
                                              p.status !== 2
                                          );
                                          if (!!!prod) {
                                            q.statusId = 1;
                                            await axios.put(
                                              "https://garment.server.highendtradingplc.com/qualityChecker/update/" +
                                                q.Id,
                                              q
                                            );
                                          } else {
                                            q.statusId = 4;
                                            await axios.put(
                                              "https://garment.server.highendtradingplc.com/qualityChecker/update/" +
                                                q.Id,
                                              q
                                            );
                                          }
                                          fetchAllData();
                                          setOpen(false);
                                          setOpenSomeApprove(false);
                                        }
                                        if (!!!q && pro !== 1) {
                                          await axios.post(
                                            "https://garment.server.highendtradingplc.com/qualityChecker/add",
                                            {
                                              productionId: qualityCheck.Id,
                                              receiveDate: new Date(),
                                              userId: roleData.Id,
                                              statusId: 3,
                                            }
                                          );
                                        }
                                        rawOrder.status = 2;
                                        await axios.put(
                                          "https://garment.server.highendtradingplc.com/productionOrder/update/" +
                                            rawOrder.Id,
                                          rawOrder
                                        );
                                      } catch (error) {
                                        console.log(error);
                                      }
                                    }}
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    variant="outline-danger"
                                    className="m-1"
                                    onClick={async (e) => {
                                      let status = statusOrders.find(
                                        (s) =>
                                          s.productionOrderRefId ===
                                          qualityCheck.productionOrderRefId
                                      );
                                      status.status = 5;
                                      const q = qualityCheckers.find(
                                        (p) =>
                                          p.productionId === qualityCheck.Id
                                      );
                                      const pro = productData.length;
                                      setProductData(
                                        productData.filter(
                                          (p) => p.Id !== rawOrder.Id
                                        )
                                      );
                                      if (!!!q && pro !== 1) {
                                        await axios.post(
                                          "https://garment.server.highendtradingplc.com/qualityChecker/add",
                                          {
                                            productionId: qualityCheck.Id,
                                            receiveDate: new Date(),
                                            userId: roleData.Id,
                                            statusId: 3,
                                          }
                                        );
                                      }
                                      if (pro === 1) {
                                        if (!!!q) {
                                          await axios.post(
                                            "https://garment.server.highendtradingplc.com/qualityChecker/add",
                                            {
                                              productionId: qualityCheck.Id,
                                              receiveDate: new Date(),
                                              userId: roleData.Id,
                                              statusId: 3,
                                            }
                                          );
                                        } else {
                                          q.statusId = 4;
                                          await axios.put(
                                            "https://garment.server.highendtradingplc.com/qualityChecker/update/" +
                                              q.Id,
                                            q
                                          );
                                        }
                                      }
                                      await axios.put(
                                        "https://garment.server.highendtradingplc.com/statusOrder/update/" +
                                          status.Id,
                                        status
                                      );
                                      rawOrder.status = 3;
                                      await axios.put(
                                        "https://garment.server.highendtradingplc.com/productionOrder/update/" +
                                          rawOrder.Id,
                                        rawOrder
                                      );

                                      fetchAllData();
                                      if (pro === 1) {
                                        setOpen(false);
                                        setOpenSomeApprove(false);
                                      }
                                    }}
                                  >
                                    Reject
                                  </Button>
                                </center>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    {!openSomeApprove && (
                      <div className="row">
                        <div className="mt-2 col">
                          <Button
                            style={{
                              width: "120px",

                              height: "40px",
                            }}
                            variant="outline-success"
                            onClick={async (e) => {
                              try {
                                let status = statusOrders.find(
                                  (s) =>
                                    s.productionOrderRefId ===
                                    qualityCheck.productionOrderRefId
                                );
                                status.status = 5;
                                const q = qualityCheckers.find(
                                  (p) => p.productionId === qualityCheck.Id
                                );
                                if (!!!q) {
                                  await axios.post(
                                    "https://garment.server.highendtradingplc.com/qualityChecker/add",
                                    {
                                      productionId: qualityCheck.Id,
                                      receiveDate: new Date(),
                                      userId: roleData.Id,
                                      statusId: 1,
                                    }
                                  );
                                } else {
                                  await axios.put(
                                    "https://garment.server.highendtradingplc.com/qualityChecker/update/" +
                                      q.Id,
                                    {
                                      productionId: qualityCheck.Id,
                                      receiveDate: new Date(),
                                      userId: roleData.Id,
                                      statusId: 1,
                                    }
                                  );
                                }
                                await axios.put(
                                  "https://garment.server.highendtradingplc.com/statusOrder/update/" +
                                    status.Id,
                                  status
                                );
                              } catch (error) {
                                console.log(error);
                              }
                              fetchAllData();
                              setOpen(false);
                            }}
                          >
                            <h6>Approve All</h6>
                          </Button>
                        </div>
                        <div className="mt-2 col">
                          <Button
                            style={{
                              width: "140px",
                              height: "40px",
                            }}
                            variant="outline-warning"
                            onClick={async (e) => {
                              setOpenSomeApprove(true);
                            }}
                          >
                            <h6>Approve/Reject</h6>
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};

export default QualityCheckerPage;
