/** @format */
import React, { useEffect, useState, useRef } from "react";
import Header from "../Header";

import { Button, Modal, Form, Col, Row, Table } from "react-bootstrap";
import { Autocomplete, Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  faUser,
  faBackwardStep,
  faFloppyDisk,
  faCalendar,
  faPenToSquare,
  faTrashCan,
  faXmark,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
const validationSchema = Yup.object().shape({
  orderDeadlineDate: Yup.date().required("Date is required!"),
  receiveDate: Yup.date().required("Date is required!"),
  comment: Yup.string().required("Comment is required!"),
});
function SalesPage() {
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [branchs, setColors] = useState([]);
  const [users, setUnits] = useState(null);
  const [productValue, setProductValue] = useState([]);
  const [productionOrders, setProductionOrder] = useState([]);
  const [salesRefs, setSalesRef] = useState([]);
  const [valids, setValid] = useState([0, 0, 0, 0, 0]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [types, setTypes] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentCheck, setPaymentCheck] = useState(null);
  const [paymentValue, setPaymentValue] = useState(null);
  const [openPrint, setOpenPrint] = useState(false);
  const [sales, setSales] = useState([]);
  const [update, setUpdate] = useState({
    productId: "",
    quantity: "",
    price: "",
  });
  const [newSaleRef, setNewSalesRef] = useState([]);
  const [newProductionOrder, setNewProductionOrder] = useState([]);
  const [price, setPrice] = useState({
    total: 0,
    vat: 0,
    grandTotal: 0,
  });
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const componentRef = useRef();
  const fetchAllData = async () => {
    try {
      const [
        customer,
        product,
        user,
        branch,
        salesRef,
        productionOrder,
        qualityChecker,
        production,
        type,
        paymentMethod,
        sale,
      ] = await Promise.all([
        axios.get("https://garment.server.highendtradingplc.com/customer"),
        axios.get("https://garment.server.highendtradingplc.com/products"),
        axios.get("https://garment.server.highendtradingplc.com/user"),
        axios.get("https://garment.server.highendtradingplc.com/branch"),
        axios.get("https://garment.server.highendtradingplc.com/salesRef"),
        axios.get(
          "https://garment.server.highendtradingplc.com/productionOrder"
        ),
        axios.get(
          "https://garment.server.highendtradingplc.com/qualityChecker"
        ),
        axios.get("https://garment.server.highendtradingplc.com/production"),
        axios.get("https://garment.server.highendtradingplc.com/type"),
        axios.get("https://garment.server.highendtradingplc.com/paymentMethod"),
        axios.get("https://garment.server.highendtradingplc.com/sales"),
      ]);
      setPaymentMethods(paymentMethod.data);
      setCustomers(customer.data);
      setProducts(product.data);
      setColors(branch.data);
      setUnits(user.data);
      setTypes(type.data);
      setSales(sale.data);
      let data = [];
      salesRef.data.map((a) => {
        const p = production.data?.find(
          (pp) => pp.productionOrderRefId === a.productionOrderRefId
        );
        const q = qualityChecker.data?.find(
          (qq) => qq.productionId === p?.Id && qq.statusId === 1
        );
        if (!!q && a.statusId === 1) {
          data.push(a);
        }
      });
      setSalesRef(data);
      setProductionOrder(productionOrder.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleUpdate = (id) => {
    setOpen(!open);
    const d = salesRefs.find((s) => s.Id === id);
    const da = productionOrders.filter(
      (f) => f.productionOrderRefId === d.productionOrderRefId
    );
    setNewProductionOrder(da);
    setNewSalesRef(d);
  };
  const paymentData = {
    options: paymentMethods,
    getOptionLabel: (option) => option?.name,
  };
  const handleValidity = (i) => {
    valids[i] = 1;
    setValid([...valids]);
  };
  const formik = useFormik({
    initialValues: {
      comment: "",
      receiveDate: "",
      price: "",
      paymentMethodId: "",
      transferidId: "",
    },
    validationSchema: Yup.object().shape({
      comment: Yup.string()
        .required("Comment is required!")
        .min(5, "Too Short!")
        .max(50, "Too Long!"),
      transferidId: Yup.string().required("Transferid ID is required!"),
      price: Yup.number()
        .min(
          newSaleRef.remainPrice,
          `The Remain Price is ${newSaleRef.remainPrice}`
        )
        .max(
          newSaleRef.remainPrice,
          `The Remain Price is ${newSaleRef.remainPrice}`
        ),
    }),
    onSubmit: async (values) => {
      try {
        console.log(newSaleRef.remainPrice !== 0, values.price === "");
        if (newSaleRef.remainPrice !== 0 && values.price === "") {
          formik.setErrors({ price: "Remain Price is required!" });
          return;
        }
        newSaleRef.comment = values.comment;
        newSaleRef.remainPrice = 0;
        newSaleRef.statusId = 2;
        newSaleRef.receiveDate = new Date();
        newSaleRef.userId = roleData.Id;
        newSaleRef.paymentMethodId = values.paymentMethodId;
        newSaleRef.transferidId = values.transferidId;
        await axios.put(
          "https://garment.server.highendtradingplc.com/salesRef/update/" +
            newSaleRef.Id,
          newSaleRef
        );
        for (let i = 0; i <= newProductionOrder.length - 1; ) {
          await axios.post(
            "https://garment.server.highendtradingplc.com/sales/add",
            {
              productId: newProductionOrder[i].productId,
              salesRefId: newSaleRef.Id,
              price: newProductionOrder[i].price,
              quantity: newProductionOrder[i].quantity,
            }
          );
          i = i + 1;
        }
        setOpenPrint(true);
        setOpen(false);
      } catch (error) {
        console.log(error);
      }
    },
  });
  const columns = [
    {
      field: "Id",
      headerAlign: "center",
      align: "center",
      headerName: "ID",
      minWidth: 80,
    },
    {
      field: "customerId",
      headerAlign: "center",
      align: "center",
      headerName: "Customer Name",
      minWidth: 150,
      valueGetter: (params) => customers.find((c) => c.Id === params)?.fullName,
    },

    {
      field: "branchId",
      headerAlign: "center",
      align: "center",
      headerName: "Branch",
      minWidth: 150,
      valueGetter: (params) => branchs.find((c) => c.Id === params)?.location,
    },
    {
      field: "totalPrice",
      headerAlign: "center",
      align: "center",
      headerName: "Total Price",
      minWidth: 100,
    },
    {
      field: "remainPrice",
      headerAlign: "center",
      align: "center",
      headerName: "Remain Price",
      minWidth: 100,
    },
    {
      field: "orderDeadlineDate",
      headerAlign: "center",
      align: "center",
      headerName: "Order Deadline",
      minWidth: 120,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];
  const columnsOrder = [
    {
      field: "Id",
      headerClassName: "super-app-theme--header",
      headerName: "#",
      width: 50,
    },
    {
      field: "product",
      headerClassName: "super-app-theme--header",
      headerName: "Product",
      width: 120,
    },
    {
      field: "type",
      headerClassName: "super-app-theme--header",
      headerName: "Type",
      width: 100,
    },
    {
      field: "scale1",
      headerClassName: "super-app-theme--header",
      headerName: "የሸ. ቁመት/ ከወ. ቁመት/ ቁመት",
      width: 80,
    },
    {
      field: "scale2",
      headerClassName: "super-app-theme--header",
      headerName: "ወገብ ዙሪያ/ ወገብ",
      width: 80,
    },
    {
      field: "scale3",
      headerClassName: "super-app-theme--header",
      headerName: "ትክሻ / ታፋ",
      width: 80,
    },
    {
      field: "scale4",
      headerClassName: "super-app-theme--header",
      headerName: "የእጅ ቁመት/ ዳሌ",
      width: 80,
    },
    {
      field: "scale5",
      headerClassName: "super-app-theme--header",
      headerName: "ደረት/ ጡት ዙሪያ",
      width: 80,
    },
    {
      field: "scale6",
      headerClassName: "super-app-theme--header",
      headerName: "ከትክሻ ወገብ",
      width: 80,
    },
    {
      field: "quantity",
      headerClassName: "super-app-theme--header",
      headerName: "Qty",
      width: 80,
    },
    {
      field: "unit",
      headerClassName: "super-app-theme--header",
      headerName: "U.Price",
      width: 100,
    },
    {
      field: "price",
      headerClassName: "super-app-theme--header",
      headerName: "T.Price",
      width: 100,
    },
  ];

  const rowsOrder = newProductionOrder.map((prod) => {
    const product = products.find((p) => p.Id === prod.productId);
    const type = types.find((t) => t.Id === product.typeId);
    return {
      Id: prod.Id,
      product: product?.name,
      type: type?.typeName,
      scale1: prod?.scale1,
      scale2: prod?.scale2,
      scale3: prod?.scale3,
      scale4: prod?.scale4,
      scale5: prod?.scale5,
      scale6: prod?.scale6,
      quantity: prod?.quantity,
      unit: prod?.price,
      price: parseFloat(prod?.quantity) * parseFloat(prod?.price),
    };
  });

  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "190px" }}>
        {!openPrint && (
          <center>
            <div style={{ maxWidth: "1100px" }}>
              <div
                className="tab-container "
                style={{
                  marginTop: "-20px",
                  marginBottom: "-5px",
                }}
              >
                <Link
                  className="p-2"
                  onClick={(e) => window.location.reload()}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px 5px 0 0",
                    border: "solid 0.05em rgb(181, 181, 181)",
                  }}
                >
                  Sales
                </Link>
                <Link className="p-2" to={`/sales_list`}>
                  Sales List
                </Link>
              </div>
              <div className="card card-info card-outline">
                <div className="card-header">
                  <h4 style={{ float: "left" }}>New Sales Entry</h4>
                  {open && (
                    <Button
                      onClick={(e) => {
                        setOpen(false);
                      }}
                      variant="outline-success"
                      className="m-1"
                      style={{ float: "right" }}
                    >
                      <div
                        className="row"
                        style={{
                          marginBottom: "-10px",
                          marginLeft: "-10px",
                        }}
                      >
                        <div className="col">
                          <FontAwesomeIcon
                            style={{ float: "right", marginRight: "-1px" }}
                            icon={faBackwardStep}
                          />
                        </div>
                        <div className="col-7">
                          <h6
                            style={{
                              float: "left",
                              marginLeft: "-10px",
                            }}
                          >
                            Back
                          </h6>
                        </div>
                      </div>
                    </Button>
                  )}
                </div>
                <div className="card-body">
                  <div
                    className="nav-bra pl-3 ml-1 p-2 "
                    style={{
                      backgroundColor: "rgb(235, 235, 235)",
                      height: "40px",
                    }}
                  >
                    <span style={{ float: "left" }}> Sales / Create</span>
                  </div>
                  <hr />
                  {!open && (
                    <div>
                      <DataGrid
                        rows={salesRefs}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        columns={columns}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        initialState={{
                          ...salesRefs.initialState,
                          pagination: {
                            paginationModel: { pageSize: 5 },
                          },
                        }}
                        onCellClick={(row) => handleUpdate(row.id)}
                        pageSizeOptions={[5, 10, 15]}
                        getRowId={(row) => row.Id}
                      />
                    </div>
                  )}
                  {open && (
                    <div>
                      <Row className="mb-2 ml-3">
                        <Col lg={3} style={{ width: "300px" }}>
                          <span style={{ float: "left" }}>
                            <b>Name :</b>{" "}
                            {
                              customers.find((c) => newSaleRef.customerId)
                                ?.fullName
                            }
                          </span>
                        </Col>
                        <Col lg={3} style={{ width: "300px" }}>
                          <span style={{ float: "left" }}>
                            <b>Branch :</b>{" "}
                            {
                              branchs.find((b) => b.Id === newSaleRef.branchId)
                                ?.location
                            }
                          </span>
                        </Col>
                      </Row>
                      <div className="row">
                        {newProductionOrder.map((rawOrder) => (
                          <div className="col-md-6 col-lg-4 mb-2 ">
                            <div
                              className="row p-3"
                              style={{
                                width: "290px",
                                height: "300px",
                                borderRadius: "10px",
                                background: "rgb(235, 235, 235)",
                              }}
                            >
                              <div className="mt-3 col-6">
                                <img
                                  src={`https://garment.server.highendtradingplc.com/${
                                    products.find(
                                      (p) => p.Id === rawOrder.productId
                                    ).photo
                                  }`}
                                  style={{
                                    width: "120px",
                                    height: "120px",
                                  }}
                                  alt="Photo"
                                />
                              </div>
                              <div
                                className=" mb-3  col-6"
                                style={{ marginTop: "-30px" }}
                              >
                                {!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    p.typeId === 1
                                ) && (
                                  <>
                                    <br />
                                    <div style={{ float: "left" }}>
                                      {" "}
                                      <b>{`የሸሚዝ ቁመት`}:</b>{" "}
                                      <span>{rawOrder.scale4}</span>
                                    </div>
                                  </>
                                )}
                                {!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    p.typeId === 2
                                ) && (
                                  <>
                                    <br />
                                    <div style={{ float: "left" }}>
                                      {" "}
                                      <b>{`ከወገብ ቁመት`}:</b>{" "}
                                      <span>{rawOrder.scale4}</span>
                                    </div>
                                  </>
                                )}
                                {!!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    (p.typeId === 2 || p.typeId === 1)
                                ) && (
                                  <>
                                    <br />
                                    <div style={{ float: "left" }}>
                                      {" "}
                                      <b>{`ቁመት`}:</b>{" "}
                                      <span>{rawOrder.scale4}</span>
                                    </div>
                                  </>
                                )}
                                {!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    (p.typeId === 2 || p.typeId === 1)
                                ) && (
                                  <>
                                    <br />
                                    <div style={{ float: "left" }}>
                                      {" "}
                                      <b>{`ወገብ ዙሪያ`}:</b>{" "}
                                      <span>{rawOrder.scale4}</span>
                                    </div>
                                  </>
                                )}
                                {!!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    (p.typeId === 2 || p.typeId === 1)
                                ) && (
                                  <>
                                    <br />
                                    <div style={{ float: "left" }}>
                                      {" "}
                                      <b>{`ወገብ`}:</b>{" "}
                                      <span>{rawOrder.scale4}</span>
                                    </div>
                                  </>
                                )}
                                {!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    (p.typeId === 2 || p.typeId === 1)
                                ) && (
                                  <>
                                    <br />
                                    <div style={{ float: "left" }}>
                                      {" "}
                                      <b>{`ትክሻ`}:</b>{" "}
                                      <span>{rawOrder.scale4}</span>
                                    </div>
                                  </>
                                )}
                                {!!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    (p.typeId === 2 || p.typeId === 1)
                                ) && (
                                  <>
                                    <br />
                                    <div style={{ float: "left" }}>
                                      {" "}
                                      <b>{`ታፋ`}:</b>{" "}
                                      <span>{rawOrder.scale4}</span>
                                    </div>
                                  </>
                                )}

                                {!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    (p.typeId === 2 || p.typeId === 1)
                                ) && (
                                  <>
                                    <br />
                                    <div style={{ float: "left" }}>
                                      {" "}
                                      <b>{`የእጅ ቁመት`}:</b>{" "}
                                      <span>{rawOrder.scale4}</span>
                                    </div>
                                  </>
                                )}
                                {!!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    (p.typeId === 2 || p.typeId === 1)
                                ) && (
                                  <>
                                    <br />
                                    <div style={{ float: "left" }}>
                                      {" "}
                                      <b>{`ዳሌ`}:</b>{" "}
                                      <span>{rawOrder.scale4}</span>
                                    </div>
                                  </>
                                )}
                                {!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    p.typeId === 2
                                ) && (
                                  <>
                                    <br />
                                    <div style={{ float: "left" }}>
                                      <b>{`ጡት ዙሪያ`}:</b>{" "}
                                      <span>{rawOrder.scale5}</span>
                                    </div>
                                  </>
                                )}
                                {!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    p.typeId === 1
                                ) && (
                                  <>
                                    <br />
                                    <div style={{ float: "left" }}>
                                      <b>{`ደረት ዙሪያ`}:</b>{" "}
                                      <span>{rawOrder.scale5}</span>
                                    </div>
                                  </>
                                )}
                                {!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    p.typeId === 2
                                ) && (
                                  <>
                                    <br />
                                    <div style={{ float: "left" }}>
                                      <b>{`ከትክሻ ወገብ`}:</b>{" "}
                                      <span>{rawOrder.scale6}</span>
                                    </div>
                                  </>
                                )}
                                <br />
                                <div style={{ float: "left" }}>
                                  {" "}
                                  <b>Quantity:</b>{" "}
                                  <span>{rawOrder.quantity}</span>
                                </div>
                                <br />
                                <div style={{ float: "left" }}>
                                  {" "}
                                  <b>Price:</b> <span>{rawOrder.price}</span>
                                </div>
                              </div>

                              <div style={{ float: "none" }} className="">
                                {!!products.find(
                                  (p) =>
                                    p.Id === rawOrder.productId &&
                                    p.typeId === 3
                                ) && (
                                  <>
                                    <br />
                                  </>
                                )}
                                <div
                                  style={{
                                    float: "left",
                                    marginTop: "-20px",
                                  }}
                                  className=""
                                >
                                  <b className="">Name:</b>
                                  <span className="">
                                    {
                                      products.find(
                                        (p) => p.Id === rawOrder.productId
                                      )?.name
                                    }
                                  </span>
                                </div>
                                <br />
                                <div
                                  style={{
                                    float: "left",
                                    marginTop: "-20px",
                                  }}
                                  className=""
                                >
                                  <b className="">Type:</b>{" "}
                                  <span className="">
                                    {
                                      types.find(
                                        (t) =>
                                          t.Id ===
                                          products.find(
                                            (p) => p.Id === rawOrder.productId
                                          )?.typeId
                                      )?.typeName
                                    }
                                  </span>
                                </div>
                                <br />
                                <div
                                  style={{
                                    float: "left",
                                    marginTop: "-20px",
                                  }}
                                  className=""
                                >
                                  <b className="">Total Price:</b>{" "}
                                  <span className="">
                                    {parseFloat(rawOrder.quantity) *
                                      parseFloat(rawOrder.price)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        className="mb-3 mt-3"
                        style={{ border: "solid 1px black" }}
                      >
                        {" "}
                      </div>

                      <Row className="m-4">
                        <Col lg={4} style={{ width: "300px" }}>
                          <span style={{ float: "left" }}>
                            <b>Total Price :</b> {newSaleRef.totalPrice}
                          </span>
                        </Col>
                        <Col lg={3} style={{ width: "300px" }}>
                          <span style={{ float: "left" }}>
                            <b>Remain Price :</b> {newSaleRef.remainPrice}
                          </span>
                        </Col>
                      </Row>
                      <Form className="m-2 " onSubmit={formik.handleSubmit}>
                        <Form.Group
                          as={Row}
                          className="mb-3 ml-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col lg={4}>
                            <Autocomplete
                              {...paymentData}
                              id="controlled-demo"
                              size="small"
                              variant="outlined"
                              name="paymentMethodId"
                              onBlur={(e) => handleValidity(2)}
                              value={paymentValue}
                              onChange={(event, newValue) => {
                                if (!newValue?.Id) {
                                  setPaymentCheck(null);
                                  setPaymentValue(null);
                                  return;
                                }
                                setPaymentValue(newValue);
                                formik.values.paymentMethodId = newValue?.Id;
                                setPaymentCheck(5);
                              }}
                              style={{
                                width: "300px",
                                float: "left",
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={
                                    valids[2] === 1 && paymentCheck === null
                                  }
                                  label={
                                    <div style={{ float: "left" }}>
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        style={{
                                          float: "left",
                                          marginRight: "10px",
                                        }}
                                      />
                                      <span>Payment Method</span>
                                    </div>
                                  }
                                />
                              )}
                            />
                          </Col>
                          <Col lg={4}>
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              label="Tranferid ID"
                              type="text"
                              size="small"
                              name="transferidId"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.transferidId}
                              error={
                                formik.touched.transferidId &&
                                formik.errors.transferidId
                              }
                              helperText={
                                formik.touched.transferidId &&
                                formik.errors.transferidId
                                  ? `${formik.errors.transferidId}`
                                  : ""
                              }
                              style={{ width: "300px", float: "left" }}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col lg={4}>
                            <TextField
                              id="outlined-basic"
                              label="Remain Price"
                              variant="outlined"
                              size="small"
                              type="number"
                              name="price"
                              className="ml-2"
                              disabled={
                                newSaleRef.remainPrice === 0 ? true : false
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={
                                newSaleRef.remainPrice === 0
                                  ? 0
                                  : formik.values.price
                              }
                              error={
                                formik.touched.price && formik.errors.price
                              }
                              helperText={
                                formik.touched.price && formik.errors.price
                                  ? `${formik.errors.price}`
                                  : ""
                              }
                              style={{ width: "300px" }}
                            />
                          </Col>
                          <Col lg={4}>
                            <TextField
                              id="outlined-basic"
                              label="Comment"
                              variant="outlined"
                              size="small"
                              multiline
                              type="text"
                              name="comment"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.comment}
                              error={
                                formik.touched.comment && formik.errors.comment
                              }
                              helperText={
                                formik.touched.comment && formik.errors.comment
                                  ? `${formik.errors.comment}`
                                  : ""
                              }
                              style={{ width: "300px" }}
                            />
                          </Col>
                          <Col lg={4}>
                            <Button
                              style={{ width: "100px" }}
                              variant="outline-success"
                              type="submit"
                            >
                              Submit
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </center>
        )}
        {openPrint && (
          <center>
            <div
              className="card card-info card-outline"
              style={{ width: "1100px", overflow: "scroll" }}
            >
              <div className="card-body m-1">
                <Container ref={componentRef}>
                  <div className="row mt-2">
                    <div className="col-sm-6 ">
                      <br />
                      <h1 style={{ marginLeft: "5vw", marginBottom: "2vh" }}>
                        Invoice
                      </h1>
                      <i
                        style={{ marginLeft: "7vw", marginBottom: "2vh" }}
                        className="fas fa-home fa-3x"
                      />
                    </div>

                    <div className="col-sm-6 ">
                      <br />
                      <div
                        style={{
                          fontSize: 18,
                          textAlign: "right",
                        }}
                      >
                        <p style={{ margin: "0", fontSize: 24 }}>
                          <b> Company Name</b>
                        </p>
                        <p style={{ margin: "0" }}>
                          {
                            branchs.find((b) => b.Id === newSaleRef.branchId)
                              .phoneNumber
                          }
                        </p>
                        <p style={{ margin: "0" }}>email@gmail.com</p>
                        <p style={{ margin: "0" }}>
                          {
                            branchs.find((b) => b.Id === newSaleRef.branchId)
                              .location
                          }
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <Row className="">
                    <Col sm={2}>
                      <p>
                        <b>BILL To</b>
                      </p>
                    </Col>

                    <Col sm={5}>
                      <div>
                        <p>
                          <b>Full Name : </b>
                          {
                            customers.find(
                              (c) => c.Id === newSaleRef.customerId
                            )?.fullName
                          }
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Phone No : </b>
                          {
                            customers.find(
                              (c) => c.Id === newSaleRef.customerId
                            )?.phoneNumber
                          }
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Payment Method : </b>
                          {paymentValue?.name}
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Remain Payment : </b>
                          {formik.values.price}
                        </p>
                      </div>
                    </Col>
                    <Col sm={5}>
                      <div>
                        <p>
                          <b>RefId : </b>
                          {`${
                            customers.find(
                              (c) => c.Id === newSaleRef.customerId
                            ).Id
                          }${new Date().getUTCDay()}${new Date().getUTCFullYear()}${new Date().getUTCMonth()}`}
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Tranferid ID : </b>
                          {`${formik.values.transferidId}`}
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Receive Date : </b>
                          {`${new Date().getUTCDate()}/${
                            new Date().getUTCMonth() + 1
                          }/${new Date().getUTCFullYear()}`}
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Deadline Date : </b>
                          {`${new Date(
                            newSaleRef.orderDeadlineDate
                          ).getUTCDate()}/${
                            new Date(
                              newSaleRef.orderDeadlineDate
                            ).getUTCMonth() + 1
                          }/${new Date(
                            newSaleRef.orderDeadlineDate
                          ).getUTCFullYear()}`}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ border: "solid 2px black" }}></div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />

                  <Row>
                    <Table className="text-center" style={{ width: "100%" }}>
                      <tr>
                        <th>No</th>
                        <th>Product</th>
                        <th>Type</th>
                        <th
                          style={{ width: "100px" }}
                        >{`የሸ. ቁመት/ ከወ. ቁመት/ ቁመት`}</th>
                        <th>{`ወገብ ዙሪያ/ ወገብ`}</th>
                        <th>{`ትክሻ / ታፋ`}</th>
                        <th>{`የእጅ ቁመት/ ዳሌ`}</th>
                        <th>{`ደረት/ ጡት ዙሪያ`}</th>
                        <th>{`ከትክሻ ወገብ`}</th>
                        <th>Qty</th>
                        <th>U.Price</th>
                        <th>T.Price</th>
                      </tr>

                      <tbody>
                        {newProductionOrder.map((request, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>

                            <td>
                              {products.map((product) =>
                                product.Id === parseInt(request.productId)
                                  ? product.name
                                  : ""
                              )}
                            </td>
                            <td>
                              {products.map((product) =>
                                product.Id === parseInt(request.productId)
                                  ? types.map((type) =>
                                      type.Id === parseInt(product.typeId)
                                        ? type.typeName
                                        : ""
                                    )
                                  : ""
                              )}
                            </td>
                            <td>{request.scale1}</td>
                            <td>{request.scale2}</td>
                            <td>{request.scale3}</td>
                            <td>{request.scale4}</td>
                            <td>{request.scale5}</td>
                            <td>{request.scale6}</td>
                            <td>{request.quantity}</td>
                            <td>{request.price}</td>
                            <td>{request.quantity * request.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Row>
                  <Row>
                    <Col sm={8}>
                      <h3>Terms</h3>
                    </Col>
                    <Col sm={4}>
                      <Table>
                        <tbody>
                          <tr style={{}}>
                            <td>
                              <b
                                style={{
                                  float: "right",
                                  fontSize: 18,
                                  width: "100px",
                                  height: "10px",
                                }}
                              >
                                Total Price
                              </b>
                            </td>
                            <td>
                              <p
                                style={{
                                  float: "left",
                                  fontSize: 18,
                                  width: "110px",
                                  height: "10px",
                                }}
                              >
                                {newSaleRef.totalPrice}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b
                                style={{
                                  float: "right",
                                  fontSize: 18,
                                  width: "100px",
                                  height: "10px",
                                }}
                              >
                                VAT
                              </b>
                            </td>
                            <td>
                              <p
                                style={{
                                  float: "left",
                                  fontSize: 18,
                                  width: "110px",
                                  height: "10px",
                                }}
                              >
                                0
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b
                                style={{
                                  float: "right",
                                  fontSize: 18,
                                  width: "100px",
                                  height: "10px",
                                }}
                              >
                                Grand Total
                              </b>
                            </td>
                            <td>
                              <p
                                style={{
                                  float: "left",
                                  fontSize: 18,
                                  width: "110px",
                                  height: "10px",
                                }}
                              >
                                {newSaleRef.totalPrice}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="card-footer row">
                <div className="col">
                  <center>
                    <ReactToPrint
                      trigger={() => (
                        <Button variant="outline-primary">Print</Button>
                      )}
                      content={() => componentRef.current}
                      onAfterPrint={() => {
                        window.location.reload();
                      }}
                    />
                  </center>
                </div>
                <div className="col">
                  <center>
                    <Button
                      variant="outline-success"
                      onClick={(e) => {
                        window.location.reload();
                      }}
                    >
                      Cancel
                    </Button>
                  </center>
                </div>
              </div>
            </div>
          </center>
        )}
      </main>
    </div>
  );
}
export default SalesPage;
