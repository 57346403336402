/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Modal, Form, Row } from "react-bootstrap";
import { Box, TextField } from "@mui/material";
const RawPurchaseList = () => {
  const [purchasesRef, setPurchasesRef] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [newPurchases, setNewPurchases] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [allPurchaseRef, setAllPurchaseRef] = useState([]);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [open, setOpen] = useState(false);
  const [purcahseDate, setPurcahseDate] = useState({
    start: new Date(new Date().setUTCDate(new Date().getUTCDate() - 1)),
    end: new Date(new Date().setUTCDate(new Date().getUTCDate() + 1)),
  });
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    try {
      const [purchaseRef, purchase, vendor, rawMaterial] = await Promise.all([
        axios.get(
          "https://garment.server.highendtradingplc.com/rawPurchaseRef"
        ),
        axios.get("https://garment.server.highendtradingplc.com/rawPurchase"),
        axios.get("https://garment.server.highendtradingplc.com/vendor"),
        axios.get("https://garment.server.highendtradingplc.com/rawMaterial"),
      ]);
      setAllPurchaseRef(purchaseRef.data);
      setVendors(vendor.data);
      setPurchases(purchase.data);
      setRawMaterials(rawMaterial.data);
      setPurchasesRef(purchaseRef.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const handleView = (row) => {
    const order = purchases.filter((p) => p.rawPurchaseRefId === row.id);
    setNewPurchases(order);
    setOpen(true);
  };
  const purchaseColumns = [
    {
      field: "Id",
      headerName: "Id",
      width: 100,
    },
    {
      field: "id",
      headerName: "Purchase ID",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "rawMaterial",
      headerName: "Raw Material",
      width: 150,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
    },
    {
      field: "qty",
      headerName: "Qty",
      width: 100,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      width: 100,
    },
  ];

  const purchaseRows = newPurchases.map((p, index) => {
    const rawMaterial = rawMaterials.find((r) => r.Id === p.rawMaterialId);
    return {
      Id: p.Id,
      id: p.rawPurchaseRefId,
      rawMaterial: rawMaterial.name,
      price: p.price,
      qty: p.quantity,
      totalPrice: parseFloat(p.price) * parseFloat(p.quantity),
    };
  });
  const columns = [
    {
      field: "Id",
      headerName: "#",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "id",
      headerName: "PurchaseID",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fullName",
      headerName: "Vendor Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
  ];

  const rows = purchasesRef?.map((purchase, index) => {
    const vendor = vendors.find((s) => s.Id === purchase.vendorId);
    return {
      Id: index + 1,
      id: purchase.Id,
      fullName: vendor.fullName,
      price: purchase.totalPrice,
      date: purchase.date,
    };
  });

  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div
              className="tab-container "
              style={{
                marginTop: "-20px",
                marginBottom: "-17px",
              }}
            >
              <Link className="p-2" to={`/raw_material`}>
                Raw Material
              </Link>
              <Link className="p-2" to={`/products`}>
                Product
              </Link>
              <Link className="p-2" to={`/raw_purchase`}>
                Raw Purchase
              </Link>
              <Link
                className="p-2"
                onClick={(e) => window.location.reload()}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px 5px 0 0",
                  border: "solid 0.05em rgb(181, 181, 181)",
                }}
              >
                Purchase List
              </Link>
            </div>
            <hr />
            <div className="card card-info card-outline">
              <div className="card-body">
                <div
                  className="nav-bra pl-3 ml-1 p-2 "
                  style={{
                    backgroundColor: "rgb(235, 235, 235)",
                    height: "40px",
                  }}
                >
                  <span style={{ float: "left" }}>
                    Home / Raw Purchase List
                  </span>
                </div>
                <br />
                <div style={{ border: "solid 1px #ffb71b" }}></div>

                <Box
                  sx={{
                    height: 300,
                    width: "100%",
                    "& .super-app-theme--header": {
                      backgroundColor: "#1de5ec",
                      color: "white",
                    },
                  }}
                >
                  <DataGrid
                    rows={rows}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    columns={columns}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    initialState={{
                      ...purchasesRef.initialState,
                      pagination: {
                        paginationModel: { pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10, 15]}
                    getRowId={(row) => row.id}
                    onCellClick={(row) => handleView(row.row)}
                  />
                </Box>
              </div>
            </div>
          </div>
        </center>
        <center>
          <Modal
            show={open}
            onHide={(e) => {
              setOpen(false);
            }}
            size="lg"
          >
            <Modal.Header closeButton>
              <span>Purchase List</span>
            </Modal.Header>
            <Modal.Body>
              <DataGrid
                rows={purchaseRows}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                columns={purchaseColumns}
                components={{
                  Toolbar: GridToolbar,
                }}
                initialState={{
                  ...newPurchases.initialState,
                  pagination: {
                    paginationModel: { pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10, 15]}
                getRowId={(row) => row.Id}
              />
            </Modal.Body>
          </Modal>
        </center>
      </main>
    </div>
  );
};
export default RawPurchaseList;
